import axios from 'axios';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputOtp } from 'primereact/inputotp';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//import logoImageDark from '../images/logos/reduced-logo-no-background-dark-mode.svg';
//import logoImage from '../images/logos/reduced-logo-no-background.svg';
import logoImageDark from '../images/logos/logo_dark.svg';
import logoImage from '../images/logos/logo_ligth.svg';

const ReactivateAccount = () => {
   const [token, setTokens] = useState();
   const btReactivate = useRef(null);
   const toast = useRef(null);
   const navigate = useNavigate();

   const activateAccount = () => {
      btReactivate.current.disabled = true;
      axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/clients/reactivate`, {activationCode: token})
      //axios.post(`http://localhost:7006/api/clients/reactivate`, {activationCode: token})
      .then(result => {
         if (result.data.result === 0) {
            setTimeout(() => {
               navigate("/");
             }, 2000);
             toast.current.show({ severity: 'success', summary: "Account Activation", detail: "Your account was succefully activated", life: 3000 });
         } else {
            btReactivate.current.disabled = false;
            toast.current.show({ severity: 'error', summary: "Reactivation Error", detail: result.data.details, life: 3000 });
         }
         
      })
      .catch(error => {
         btReactivate.current.disabled = false;
         if(axios.isCancel){
         } else {
               console.log(error)
         }
      });
   }

   return (
      <div className="card flex justify-content-center align-content-center mt-8 mb-5">
         {/* MANAGES THE TOASTS WITH THE MESSAGES */}
         <Toast ref={toast} className=""/>
      
         <Card
            header={
               <div>
                  <img className='ml-1 mr-auto w-20rem' alt="Card" src={window.matchMedia('(prefers-color-scheme: dark)').matches ? logoImageDark: logoImage} height={160}/>
                  <h1 className="flex justify-content-center align-content-center">Account Reactivation</h1>
               </div>
            }
            className="md:w-25rem shadow-8 border-round-3xl"
            style={{ width: "300px", padding: "2em" }}
            >
               <p style={{marginTop: -20}}>Please insert the <b>reactivation code</b> you received on you email </p>
               <div className="flex align-items-center justify-content-center h-4rem font-bold border-round m-2">
                  <InputOtp value={token} className="border-round-2xl" onChange={(e) => setTokens(e.value)} length={6} integerOnly/>
               </div>

               <div className="flex align-items-center justify-content-center h-4rem font-bold border-round m-2 mt-5">
                  <Button 
                  ref={btReactivate}
                     label="Reactivate"
                     icon="pi pi-check"
                     className='w-12rem border-round-2xl'
                     raised 
                     onClick={() => {
                        activateAccount();
                     }} 
                  />
               </div> 
         </Card>
      </div> 
   );
};

export default ReactivateAccount;