import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'; // icons
import 'primereact/resources/primereact.css'; // core cs
import 'primereact/resources/themes/viva-light/theme.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import RemoveAccountPage from './components/RemoveAccountPage';
//import ManageProposals from './components/proposals/ManageProposals';
//import ManageProposalPositions from './components/proposals/ManageProposalPositions';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ReactivateAccount from './components/ReactivateAccount';
import "./i18n";
import './index.css';
import reportWebVitals from './reportWebVitals';

const LightTheme = React.lazy(() => import('./themes/lightTheme'));
const DarkTheme = React.lazy(() => import('./themes/darkTheme'));

const ThemeSelector = ({ children }) => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      return (
        <>
          <React.Suspense fallback={<></>}>
            {darkThemeMq.matches ? <DarkTheme /> : <LightTheme />}
          </React.Suspense>
          {children}
        </>

      )
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeSelector>
     <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/removeaccount" element={<RemoveAccountPage />} />
        <Route path="/reactivation" element={<ReactivateAccount />} />
      </Routes>
    </Router>
    
  </ThemeSelector>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
