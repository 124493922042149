import Cookies from "js-cookie";
import mqtt from 'mqtt';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientsPageSearchs from './ClientsPageSearchs';
import ManageUsers from './ManageUsers';
import UserDetails from './UserDetails';


import axios from 'axios';


import { Dropdown } from 'primereact/dropdown';
//import logoMini from '../images/logos/logo_mini.svg';
//import logoImageDark from '../images/logos/reduced-logo-no-background-dark-mode.svg';
//import logoImage from '../images/logos/reduced-logo-no-background.svg';
import logoMini from '../images/logos/logo_d.svg';
import logoImageDark from '../images/logos/logo_dark.svg';
import logoImage from '../images/logos/logo_ligth.svg';


import '../styles/customStyle.css';


export const UserContext = createContext();

const MainApp = ({user, logoutEvent}) => {
    const { t} = useTranslation();

    const toast = useRef(null);

    const [isShowUserDetails, setShowUserDetails] = useState(false);
    const [loggedUserData, setLoggedUserData] = useState(user);

    const [isShowActivationRequests, setShowActivationRequests] = useState(false);
    const [userRequests, setUserRequests] = useState(undefined);

    const [isShowManageUsers, setShowManageUsers] = useState(false);

    const [plantOptions, setPlantOptions] = useState(null);

    //EFFECT TO LOAD CLIENTS FROM API
    useEffect(() => {
        const controller = new AbortController();

        axios.get(
            `${process.env.REACT_APP_API_ADDRESS}/api/clients/findAllClients`,
            { 
                signal: controller.signal,
                headers: {
                    "x-auth-token":  JSON.stringify(Cookies.get("token"))
                }
            }
        ).then(result => {
            setPlantOptions(result.data);
        }).catch(error => {
            if(axios.isCancel){
            } else {
                console.log(error)
            }
        })

        return () => {
            controller.abort()
        }
    }, []);

    //ACCESS TYPE OPTIONS
    const accessTypeOptions = [
        { name: t('TXT_ACCESS_OPTIONS_ALL'), code: 3 },
        { name: t('TXT_ACCESS_OPTIONS_GROUP'), code: 1 },
        { name: t('TXT_ACCESS_OPTIONS_PLANT'), code: 2 },
    ];

    //RETURN USERS ACCESS TYPE
    const getAccessType = (type) => {
        if (type === 1) {
            return  { name: t('TXT_ACCESS_OPTIONS_GROUP'), code: 1 };
        } else if (type === 3) {
            return { name: t('TXT_ACCESS_OPTIONS_ALL'), code: 3 };
        } else {
            return { name: t('TXT_ACCESS_OPTIONS_PLANT'), code: 2 };
        }
    }

    //REMOVE USER REQUEST
    const confirmRemoveRequest = (event, userID) => {
        //console.log(userID);
        confirmPopup({
            target: event.currentTarget,
            message: t('MSG_REMOVE_USER_REQUEST'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            className:'border-round-2xl',
            acceptClassName: 'p-button-danger border-round-2xl',
            rejectClassName: 'border-round-2xl p-button-outlined',
            acceptLabel: t('BTN_YES'),
            rejectLabel: t('BTN_NO'),
            accept: () => {
                axios.post(
                    `${process.env.REACT_APP_API_ADDRESS}/api/clients/removeUser`, 
                    {
                        userid: userID,
                    },
                    {
                        headers: {
                            "x-auth-token":  JSON.stringify(Cookies.get("token"))
                        }
                    }
                ).then(result => {
                    if (result.data.result === 0) {
                       toast.current.show({ severity: 'success', summary: t('TXT_NOTIFICATIONS'), detail: t('MSG_REMOVE_USER_SUCCESS'), life: 3000 });
                    } else {
                       toast.current.show({ severity: 'error', summary: t('TXT_NOTIFICATIONS'), detail: result.data.result === -1 ? t(result.data.error) : result.data.error, life: 3000 });
                    }
                });
            },
            reject: () => {}
        });
    };

    //ACCEPT USER REQUEST
    const confirmActivateRequest = (event, userRequest) => {
        //console.log(userRequest);
        confirmPopup({
            target: event.currentTarget,
            message: t('MSG_CONFIRM_USER_REQUEST'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            className: 'border-round-2xl',
            acceptClassName: 'p-button-primary border-round-2xl',
            rejectClassName: 'border-round-2xl p-button-outlined',
            acceptLabel: t('BTN_YES'),
            rejectLabel: t('BTN_NO'),
            accept: () => {
                if (userRequest.status || userRequest.siteStatus) {
                    if (userRequest !== null && userRequest !== undefined && 
                        userRequest.username !== null && userRequest.username !== undefined && userRequest.username.trim().length > 0 &&
                        userRequest.email !== null && userRequest.email !== undefined && userRequest.email.trim().length > 0 &&
                        userRequest.clientID !== null && userRequest.clientID !== undefined &&
                        userRequest.group !== null && userRequest.group !== undefined && userRequest.group.trim().length > 0 &&
                        userRequest.accessType !== null && userRequest.accessType !== undefined) {
                        axios.post(
                            `${process.env.REACT_APP_API_ADDRESS}/api/clients/activate`, 
                            {
                                clientData: JSON.stringify(userRequest)
                            },
                            {
                                headers: {
                                    "x-auth-token":  JSON.stringify(Cookies.get("token"))
                                }
                            }
                        ).then(result => {
                            if (result.data.result === 0) {
                            toast.current.show({ severity: 'success', summary: t('TXT_NOTIFICATIONS'), detail: t('MSG_USER_SUCCESS_ACTIVATED'), life: 3000 });
                            } else {
                            toast.current.show({ severity: 'error', summary: t('TXT_NOTIFICATIONS'), detail: result.data.result === -1 ? t(result.data.error) : result.data.error, life: 3000 });
                            }
                        });
                    } else {
                        toast.current.show({ severity: 'error', summary: t('TXT_NOTIFICATIONS'), detail: t('MSG_ALL_FIELDS_MUST_BE_FILLED'), life: 3000 });
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: t('TXT_NOTIFICATIONS'), detail: t('MSG_ACTIVATION_ERROR_FILLED'), life: 3000 });
                }
            },
            reject: () => {}
        });
    };

    //UPDATE FIELDS
    const updateFieldChanged = (e, index) => {
        // copying the old datas array
        let newRequests = [...userRequests]; 
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        // replace e.target.value with whatever you want to change it to
        
        if (e.target.name === 'accessType') {
            newRequests[index][e.target.name] = e.target.value.code;
        } else if (e.target.name === 'plant') {
            newRequests[index]["clientID"] = e.target.value.code;
            newRequests[index][e.target.name] = e.target.value.name;
        } else {
            newRequests[index][e.target.name] = e.target.value; 
        }

        setUserRequests(newRequests);
    }

    //EFFECT TO READ DATA FROM MQTT SERVER
    useEffect(() => {
        const options = {
            keepalive: 60,
            protocol: 'wss',
            protocolVersion: 4,
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 1000,
        }
        
        const onMessageReceived = (topic, message) => {
            if (topic === process.env.REACT_APP_MQTT_TOPIC) {
                const parsedMessage = JSON.parse(message);
                if (parsedMessage.type === 'userRequests') {
                    setUserRequests(parsedMessage.data.length > 0 ? parsedMessage.data: undefined);
                }
            } 
        }

        //console.log("Using effect MOSQuiTTO!")
        const client = mqtt.connect(process.env.REACT_APP_MQTT_ADDRESS, options);      
        client.on('connect', () => client.subscribe(process.env.REACT_APP_MQTT_TOPIC));
        client.on('message', (topic, payload, packet) => onMessageReceived(topic, payload.toString()));

        return () => {
            //console.log("CLOSING - Effect MOSQuiTTO!")
            client.unsubscribe(process.env.REACT_APP_MQTT_TOPIC)
            client.end();
        }
    }, []);

    //EFFECT TO REQUEST NOTIFICATIONS WHEN THE APP STARTS
    useEffect(() => {
        const controller = new AbortController();

        axios.get(
            `${process.env.REACT_APP_API_ADDRESS}/api/clients/notifications`, 
            {
                signal: controller.signal,
                headers: {
                    "x-auth-token":  JSON.stringify(Cookies.get("token"))
                }
            }
        ).then(result => {
            setUserRequests(result.data.data.length > 0 ? result.data.data: undefined);
        }).catch((err) => {
            //toast.current.show({ severity: 'error', summary: t('TTL_IMPORT_MODULE_CLIPS'), detail:err, life: 3000 });
        });
        
        return () => {
            controller.abort()
        }
    }, []);

    return (
        <>
            {/* {console.log('redraw')} */}
            {/* MANAGES THE TOASTS WITH THE MESSAGES */}
            <Toast ref={toast} />

             {/* MANAGES THE TOALTIPS */}
            <Tooltip target=".logout" position="bottom" content={t('TXT_LOGOUT')} className="custom-tooltip"/>
            <Tooltip target=".userdetails" position="bottom" content={t('TXT_USER_DETAILS')} className="custom-tooltip"/>
            <Tooltip target=".notifications" position="bottom" content={t('TXT_NOTIFICATIONS')} className="custom-tooltip"/>
            <Tooltip target=".usersmanagement" position="bottom" content={t('TXT_USERS_MANAGEMENT')} className="custom-tooltip"/>
            
            {/* TOP BAR MENU */}
            <Card header={
                <>
                    <div className="grid">
                        <div className="col-fixed " style={{width: window.innerWidth > 600 ? "180px" : "80px"}}>
                            <div className="text-center border-round-sm font-bold">
                                    { 
                                    window.innerWidth > 600 ? 
                                    <img alt="" src={window.matchMedia('(prefers-color-scheme: dark)').matches ? logoImageDark: logoImage} height="45" className="mt-2 ml-2 " ></img> :
                                    <img alt="" src={logoMini} height="45" className="mt-2 ml-2 " ></img>
                                    }
                            </div>
                        </div>
                        <div className="col">
                            <div className="text-center border-round-sm font-bold"></div>
                        </div>
                        <div className="col-fixed" style={{width: window.innerWidth > 600 ? "440px" : "260px"}}>
                            <div className="text-center border-round-sm font-bold mt-2 mr-2">
                                <div className='flex flex-row align-content-begin flex-wrap'>  
                                    { window.innerWidth > 600 ?        
                                    <div className='ml-auto'>
                                        <span className="font-bold">{loggedUserData.name}</span>
                                        <p className="text-xs mt-1">{'('+loggedUserData.plant+')'}</p>
                                    </div>
                                    :
                                    null
                                    }
                                    <Avatar 
                                        className="p-overlay-badge userdetails hover:bg-bluegray-500 ml-auto mr-5 shadow-4"
                                        size='large'
                                        icon="pi pi-user" 
                                        style={window.matchMedia('(prefers-color-scheme: dark)').matches ? { backgroundColor: 'var(--surface-100)', color: '#ffffff' }: { backgroundColor: 'var(--surface-600)', color: '#ffffff' }}
                                        shape="circle"
                                        onClick={() => setShowUserDetails(true)} 
                                    >
                                            <Badge value={loggedUserData.group} className="font-xs" severity={loggedUserData.accessType.toLowerCase() === '1' ? "success" : loggedUserData.accessType.toLowerCase() === '0' ? "info" : "warning"}/>
                                    </Avatar>

                                    {loggedUserData.accessType.toLowerCase() === '0' ? 
                                        <>
                                            <Avatar 
                                                className="p-overlay-badge notifications hover:bg-bluegray-500 ml-1 mr-4 shadow-4"
                                                size='large'
                                                icon="pi pi-bell" 
                                                style={window.matchMedia('(prefers-color-scheme: dark)').matches ? { backgroundColor: 'var(--surface-100)', color: '#ffffff' }: { backgroundColor: 'var(--surface-600)', color: '#ffffff' }}
                                                shape="circle"
                                                onClick={() => setShowActivationRequests(true)} 
                                            >
                                                {userRequests !== undefined ?
                                                    <Badge value={userRequests.length} className="font-xs" severity="danger"/>     
                                                :
                                                    null
                                                }
                                            </Avatar>

                                            <Avatar 
                                                className="p-overlay-badge usersmanagement hover:bg-bluegray-500 ml-1 mr-4 shadow-4"
                                                size='large'
                                                icon="pi pi-users" 
                                                style={window.matchMedia('(prefers-color-scheme: dark)').matches ? { backgroundColor: 'var(--surface-100)', color: '#ffffff' }: { backgroundColor: 'var(--surface-600)', color: '#ffffff' }}
                                                shape="circle"
                                                onClick={() => setShowManageUsers(true)} 
                                                >
                                            </Avatar>
                                        </>
                                    :
                                        null
                                    }
                                    <Avatar 
                                        className="logout hover:bg-red-500 shadow-4"
                                        icon="pi pi-power-off" 
                                        size="large" 
                                        style={{ backgroundColor: '#fa8072', color: '#ffffff' }}
                                        shape="circle" 
                                        onClick={() => logoutEvent()} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                } 
                className="h-5rem m-3 shadow-5 border-round-3xl"
            />
            
            <UserContext.Provider value={loggedUserData}>
                {/* MAIN PAGE */}
                <ClientsPageSearchs/>

                {/* USERS MANAGEMENT */}
                {
                    isShowManageUsers &&
                    <Sidebar 
                        header={
                            <>
                                <i className="pi pi-users text-lg" />
                                <h2 className='ml-3 mr-auto'>{t('TXT_USERS_MANAGEMENT')}</h2>
                            </>
                        }
                        visible={isShowManageUsers} 
                        position="right" 
                        dismissable={true} 
                        onHide={() => {
                            setShowManageUsers(false);
                        }} 
                        style={{ width: '90rem'}} 
                        className="border-round-3xl border-noround-right"
                    >
                        <ManageUsers></ManageUsers>
                    </Sidebar>
                }

                {/* USERS DETAILS*/}
                {
                    isShowUserDetails &&
                    <Sidebar 
                        header={
                            <>
                                <i className="pi pi-user text-lg" />
                                <h2 className='ml-3 mr-auto'>{t('TXT_USER_DETAILS')}</h2>
                            </>
                        }
                        visible={isShowUserDetails} 
                        position="right" 
                        dismissable={true} 
                        onHide={() => {
                            setShowUserDetails(false);
                        }} 
                        style={{ width: '22rem'}} 
                        className="border-round-3xl border-noround-right"
                    >
                        <UserDetails 
                            loggedUser={loggedUserData} 
                            onClose={() => setShowUserDetails(false)} 
                            onChangeData = {(userData) => {
                                setShowUserDetails(false);
                                setLoggedUserData(userData);
                                toast.current.show({ severity: 'success', summary: t('TTL_UPDATE_USER_DATA'), detail:t('MSG_USER_DATA_UPDATE_SUCCESS'), life: 3000 });
                            }}
                        />
                    </Sidebar>
                }

                {/* NOTIFICATIONS */}
                {
                    isShowActivationRequests &&
                    <Sidebar 
                        header={
                            <>
                                <i className="pi pi-bell text-lg" />
                                <h2 className='ml-3 mr-auto'>{t('TXT_NOTIFICATIONS')}</h2>
                            </>
                        }
                        visible={isShowActivationRequests} 
                        position="right" 
                        dismissable={true} 
                        onHide={() => {
                            setShowActivationRequests(false);
                        }} 
                        style={{ width: '22rem'}} 
                        className="border-round-3xl border-noround-right"
                    >
                        <div>  
                            <ConfirmPopup/>

                            {
                                isShowActivationRequests && userRequests !== undefined && userRequests.map((userRequest, index) => {
                                    return <div key={userRequest.userID} className={userRequest.clientID.length === 0 ? "card h-19rem shadow-5 border-round-3xl mt-1 mb-4 p-1 ": "card h-22rem shadow-5 border-round-3xl mt-1 mb-4 p-1 "}>
                                        <div className="flex align-items-center justify-content-center font-bold text-xl mt-1 ml-2 mb-2" style={{color:'var(--surface-600)'}}>{'  '+ userRequest.name}</div>

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">{t('TXT_USERNAME_SHORT')}</span>
                                            <InputText 
                                                className="h-2rem mr-2 text-sm border-round-xl border-noround-left" 
                                                name="username"
                                                value={userRequest.username} 
                                                onChange={e => updateFieldChanged(e, index)}
                                            />
                                        </div>

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">{t('TXT_EMAIL')}</span>
                                            <InputText 
                                                className="h-2rem mr-2 text-sm border-round-xl border-noround-left" 
                                                name="email"
                                                value={userRequest.email} 
                                                onChange={e => updateFieldChanged(e, index)}
                                            />
                                        </div>

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">Web Site</span>
                                            <span className="w-12rem h-2rem mr-1 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-left">
                                                <InputSwitch 
                                                    className="ml-auto mr-1 text-sm border-round-xl border-noround-left" 
                                                    style={{marginTop: 1}}
                                                    name="siteStatus"
                                                    checked={userRequest.siteStatus} 
                                                    onChange={e => updateFieldChanged(e, index)} 
                                                />
                                            </span>
                                        </div>

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">App</span>
                                            <span className="w-12rem h-2rem mr-1 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-left">
                                                <InputSwitch 
                                                    className="ml-auto mr-1 text-sm border-round-xl border-noround-left" 
                                                    style={{marginTop: 1}}
                                                    name="status"
                                                    checked={userRequest.status} 
                                                    onChange={e => updateFieldChanged(e, index)} 
                                                />
                                            </span>
                                        </div>
                                        
                                        {
                                            userRequest.clientID.length === 0 ? 
                                                <div className="p-inputgroup flex-1 mr-2 mb-1">
                                                    <span className="w-full h-1rem ml-2 mr-2 p-inputgroup-addon text-xs border-round-xl">{userRequest.plant}</span>
                                                </div>
                                            :
                                                null
                                        }

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">{t('TXT_PLANT')}</span>
                                            <Dropdown
                                                name="plant"
                                                value={{name: userRequest.plant, code: parseInt(userRequest.clientID)}} 
                                                onChange={(e) => updateFieldChanged(e, index)} 
                                                options={plantOptions} 
                                                optionLabel="name" 
                                                filter
                                                panelClassName='border-round-xl border-noround-top text-sm shadow-3 p-2'
                                                className="h-2rem mr-2 border-round-xl border-noround-left custom-dropdown" 
                                            />
                                        </div>

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">{t('TXT_GROUP')}</span>
                                            <InputText 
                                                className="h-2rem mr-2 text-sm border-round-xl border-noround-left" 
                                                name="group"
                                                value={userRequest.group} 
                                                onChange={e => updateFieldChanged(e, index)}
                                            />
                                        </div>

                                        <div className="p-inputgroup flex-1 mr-2 mb-1">
                                            <span className="w-6rem h-2rem ml-2 p-inputgroup-addon text-sm font-bold border-round-xl border-noround-right">{t('TXT_ACCESS_TYPE_SHORT')}</span>
                                            <Dropdown
                                                name="accessType"
                                                value={getAccessType(userRequest.accessType)} 
                                                onChange={(e) => updateFieldChanged(e, index)} 
                                                options={accessTypeOptions} 
                                                optionLabel="name" 
                                                panelClassName='border-round-xl border-noround-top text-sm shadow-3 p-2'
                                                className="h-2rem mr-2 border-round-xl border-noround-left custom-dropdown" 
                                            />
                                        </div>
                                    
                                        <div className="flex align-items-end justify-content-end flex-wrap mt-3 mr-3">
                                            <Button label={t('BTN_REMOVE')} className="h-2rem border-round-xl" severity="danger" text onClick={e => confirmRemoveRequest(e, userRequest.userID)}/>
                                            <Button label={t('BTN_ACTIVATE')} className="h-2rem border-round-xl" style={{color:'var(--surface-600)'}} text onClick={e => confirmActivateRequest(e, userRequest)}/>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </Sidebar>
                }
            </UserContext.Provider>
        </>
    );
}

export default MainApp;
