import axios from 'axios';
import Cookies from "js-cookie";
import sha256 from 'js-sha256';
import { jwtDecode } from "jwt-decode";
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainApp from './components/MainApp';
import UserLogin from './components/UserLogin';

import '../src/styles/customStyle.css';


const App = () => {
  const { t, i18n } = useTranslation();
  
  const [presentUI, setPresentUI] = useState(null);

  const toast = useRef(null);

  //CHECK IF THE USER VALID
  const validateUser = (result) => {
    //console.log(result.data.toString().length);
    if (result.data.toString().length > 0) {
      const decoded = jwtDecode(result.data);

      if (decoded.userID > 0) {
        if (decoded.enabled) {
          //if (result.data.user.activo) {


            Cookies.set("token", result.data, {expires: ((decoded.exp-decoded.iat)/3600)});
            setPresentUI(<MainApp user={decoded} logoutEvent={btLogout}/>)
          //} else {
          //  toast.current.show({ severity: 'error', summary: t('TTL_LOGIN_FAILED'), detail: `${result.data.user.name} ${t('MSG_IS_NOT_ENABLED')}`, life: 3000 });
          //}
        } else {
          toast.current.show({ severity: 'error', summary: t('TTL_LOGIN_FAILED'), detail: t('MSG_USER_DISABLED'), life: 3000 });
          setPresentUI(<UserLogin loginEvent={btLogin}/>);
        }
      } else {
        toast.current.show({ severity: 'error', summary: t('TTL_LOGIN_FAILED'), detail: t('MSG_USER_PASS_MISSMATCH'), life: 3000 });
        setPresentUI(<UserLogin loginEvent={btLogin}/>);
      }
    } else {
      toast.current.show({ severity: 'error', summary: t('TTL_LOGIN_FAILED'), detail: t('MSG_USER_NOT_FOUND'), life: 3000 });
      setPresentUI(<UserLogin loginEvent={btLogin}/>);
    }
  }

  //CHECK IF THE USER IS VALID AND LOGS HIM IN
  const btLogin = (user, pass, isEncrypted) => {
    axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/clients/login`, {uname: user, pass: isEncrypted ? pass : sha256(pass)})
    .then(result => {
      validateUser(result);
    })
    .catch(error => {
        if(axios.isCancel){
        } else {
            console.log(error)
        }
    })
  }

  const btLogout = () => {
    Cookies.remove("token");
    setPresentUI(<UserLogin loginEvent={btLogin}/>);
  }

  //CHECK IF USER WAS ALREADY LOGGEDIN
  useEffect(() => {
    i18n.changeLanguage(window.localStorage.getItem('language') !== undefined && window.localStorage.getItem('language') !== null ? window.localStorage.getItem('language') : 'en');
    if ( Cookies.get("token") !== undefined  ) {
      var decoded = jwtDecode(Cookies.get("token"));
      //console.log(decoded.username);
      //console.log(decoded.password);
      btLogin(decoded.username, decoded.password, true);
    } else {
      setPresentUI(<UserLogin loginEvent={btLogin}/>)
    }
  // eslint-disable-next-line
  }, []);

 /*const Loading = () => {
    return (
      <div className="loading">
        <ReactLoading type="spinningBubbles" color="#007bff" height={100} width={100} />
      </div>
    );
 }*/
  
  return (
    <>
      <Toast ref={toast} position="top-center"/>
      {(presentUI !== undefined && presentUI !== null) ? presentUI: null}
      {/* {presentUI === undefined || presentUI === null  ? Loading() : presentUI} */}
    </>
  );
}

export default App;