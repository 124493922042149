import axios from 'axios';
import Cookies from "js-cookie";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Image } from 'primereact/image';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from './MainApp';
import OrdersSearchs from './OrdersSearchs';

const ClientsPageSearchs = () => {
    const { t } = useTranslation();

    const loggedUser = useContext(UserContext);

    const [isFiltered, setFilterStatus] = useState(false);

    const [searchFilters, setSearchFilters] = useState({
        plant: {
            name: loggedUser.accessType.toString().toLowerCase() === '2' ? loggedUser.plant : loggedUser.accessType.toString().toLowerCase() === '1' ? loggedUser.group : '',
            code: loggedUser.accessType.toString().toLowerCase() === '2' ? loggedUser.plant : loggedUser.accessType.toString().toLowerCase() === '1' ? loggedUser.group : ''
        },
        workNumber: '',
        workPosition: '',
        project: '',
        po: '',
        clientID: '',
        invoice: '',
        nrWays: '',
        status: {name: '', code: ''},
        colors: {name: '', code: ''},
    });

    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);

    const [availableTecFiles, setAvailableTecFiles] = useState(undefined);
    const [selectedTecFile, setSelectedTecFile] = useState(undefined);

    const [expandedRows, setExpandedRows] = useState(null);

    const [totalRows, setTotalRows] = useState(25);
    const [refreshData, setRefreshData] = useState(false);
    
    const [isLoading, setIsLoading] = useState(true);
    //const [isShowSearchBar, setShowSearchBar] = useState(false);  
    const [isShowTecFileDownload, setShowTecFileDownload] = useState(false);   

    const toast = useRef(null);
    const searchPanel = useRef(null);
    
    //EFFECT TO LOAD ORDERS FROM API
    useEffect(() => {
        const controller = new AbortController();

        axios.get(
            `${process.env.REACT_APP_API_ADDRESS}/api/clients/findOrders`,
            { 
                signal: controller.signal, 
                params: {
                    totalRows: totalRows, 
                    plant: searchFilters.plant !== undefined ? searchFilters.plant.code : '', 
                    work: searchFilters.workNumber,
                    workpos: searchFilters.workPosition,
                    project: searchFilters.project,
                    po: searchFilters.po,
                    id: searchFilters.clientID,
                    invoice: searchFilters.invoice,
                    ways: searchFilters.nrWays,
                    status: searchFilters.status !== undefined  ? searchFilters.status.code : '',
                    color: searchFilters.colors !== undefined  ? searchFilters.colors.code : ''
                },
                headers: {
                    "x-auth-token":  JSON.stringify(Cookies.get("token"))
                }
            }
        )
        .then(result => {
            const detectionsPromisse = result.data.map(position => axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/clients/findDetections`,{signal: controller.signal, params: {order: position.orderNr, position: position.orderPosition}, headers: {"x-auth-token":  JSON.stringify(Cookies.get("token"))}}))

            Promise.all(detectionsPromisse).then((detectionsFeatures) => { 
                result.data.forEach((position) => {
                    detectionsFeatures.forEach(result => {
                        if (result.data.length > 0) {
                            if (position.orderNr === result.data[0].orderNr && parseInt(position.orderPosition) === parseInt(result.data[0].orderPosition)) {
                                position.detections = result.data
                            }
                        }
                    })
                })

                setIsLoading(false);
                setData(result.data);

                //console.log(result.data);
            })
            .catch((err) => {
                //toast.current.show({ severity: 'error', summary: t('TTL_IMPORT_MODULE_CLIPS'), detail:err, life: 3000 });
            });
        })
        .catch(error => {
            if(axios.isCancel){
            } else {
                console.log(error)
            }
        })

        return () => {
            controller.abort()
        }
    }, [
        searchFilters, 
        totalRows, 
        refreshData
    ]);


    //RETURNS THE EXPANDED ROW TEMPLATE WITH ALL THE INFORMATION
    const expandedRowTemplate = (position) => {
        let detections = [];
        let features = [];
        let zones = [];

        position !== undefined && position.detections !== undefined && position.detections.forEach((item) => {    
            if (!item.isFeature)
            detections.push(item);
            else
            features.push(item);
        });

        if (position !== undefined && position.zones !== undefined) {
            let data = position.zones.toString().split(";");

            data.forEach((zone) => {
                if (zone.toString().trim().length > 0)
                    zones.push({name:zone});
            });
        }

        return (position !== undefined && 
            <div style={{height:'30rem'/*, backgroundColor:'var(--surface-100)'*/}}>
                <div className="grid">
                    <div className="col">
                    </div>
                    <div className="col">
                        <Card style={{ width: '22rem', height:'13rem'}} className='ml-auto mr-0 shadow-8 border-round-3xl'>
                            <Image style={{marginTop: "-20px"}} src={getComponentImage(position.componentPath)} zoomSrc={getComponentImage(position.componentPath)} alt="" width="312" height="170" className="rounded-image" preview/>
                        </Card>
                        <Card title={`${t('TXT_ARTICLE_DESCRIPTION')} (${position.articeTypeCode})`} style={{width: '22rem', height:'7rem', padding: 0}} className='mt-3 ml-auto mr-0 shadow-8 border-round-3xl p-0'>
                            <p style={{marginTop: "-20px"}} className='font-bold'>{position.family}</p>
                            <p style={{marginTop: "-13px"}} className=''>{position.article}</p>
                        </Card> 
                        <Card title={t('TXT_DINEFER_ID')} style={{ width: '22rem', height:'7rem'}} className='mt-3 ml-auto mr-0 shadow-8 border-round-3xl'>
                            <p className='mt-0 font-bold'>{position.dineferID}</p>
                        </Card>     
                    </div>
                    <div className="col">
                        <Card title={t('TXT_SPECIFICATIONS')} style={{ width: '44rem', height:'29rem'}} className='shadow-8 border-round-3xl'>
                            <div className="grid">
                                <div className="col">
                                    <h3 className='mt-0'>{t('TXT_DETECTIONS')}</h3>
                                    <DataTable 
                                        emptyMessage={t('TXT_NO_DATA_FOUND')}
                                        value={detections} 
                                        size='small' 
                                        scrollable
                                        scrollHeight={'19rem'}
                                        tableStyle={{ width: '20rem' } } 
                                        className='text-sm'
                                    >
                                        <Column field="shortName" header=" " style={{width:"3rem"}}/>
                                        <Column field="designationEnglish" header={t('TXT_TBL_NAME')}/>
                                        <Column header="H" style={{width:"3rem"}} body={(rowData) => {
                                            return (
                                                <React.Fragment>
                                                    {rowData.type === 'H' ? rowData.quantity : ''}                                           
                                                </React.Fragment>
                                            )}}  
                                        />
                                         <Column header="V" style={{width:"3rem"}} body={(rowData) => {
                                            return (
                                                <React.Fragment>
                                                    {rowData.type === 'V' ? rowData.quantity : ''}                                           
                                                </React.Fragment>
                                            )}}  
                                        />
                                    </DataTable>
                                </div>
                                <div className="col">
                                <h3 className='mt-0'>{t('TXT_FEATURES')}</h3>
                                    <DataTable 
                                        emptyMessage={t('TXT_NO_DATA_FOUND')}
                                        value={features} 
                                        size='small' 
                                        scrollable
                                        scrollHeight={'19rem'}
                                        tableStyle={{ width: '20rem' } } 
                                        className='text-sm'
                                    >
                                        <Column field="shortName" header=" " style={{width:"3rem"}}></Column>
                                        <Column field="designationEnglish" header={t('TXT_TBL_NAME')}></Column>
                                        <Column field="quantity" header={t('TXT_TBL_QTY')} style={{width:"3rem"}}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col">
                        <Card title={t('TXT_ZONES')} style={{ width: '18rem', height:'29rem'}} className='shadow-8 border-round-3xl'>
                            <DataTable 
                                emptyMessage={t('TXT_NO_DATA_FOUND')}
                                value={zones} 
                                size='small' 
                                scrollable
                                scrollHeight={'23rem'}
                                tableStyle={{ width: '15rem' } } 
                                className='text-sm'
                            >
                                <Column field="name" header={t('TXT_TBL_NAME')}></Column>
                            </DataTable>
                        </Card> 
                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>
        );
    }

    //RETURN THE IMAGE PATH
    const getComponentImage = (currPath) => {
        var path = currPath.toString().split("\\");
        var finalPath = "";
        for (let i = 1; i < path.length-1; i++) {
            finalPath += path[i]+"/";
        }

        return "https://clients.dinefer.com/"+(finalPath+path[path.length-1]);
    }

    //HANDLE PROMISSE
    const handlePromise = (promise) => {
        return promise
          .then(data => ({ success: true, data }))
          .catch(error => ({ success: false, error }));
      }
      

    //RETURN TEC FILE
    const getTecFile = (row) => {
        const username = process.env.REACT_APP_API_NGINX_ACCESS_USER;
        const password = process.env.REACT_APP_API_NGINX_ACCESS_PASS;

        const authHeader = 'Basic ' + btoa(username + ':' + password);

        let tecFileLanguages = [];

        let ano = `20${row.orderNr.slice(-2)}`;
        let orderPath = row.orderNr.length === 4 ? `000${row.orderNr}` :  row.orderNr.length === 5 ? `00${row.orderNr}` : row.orderNr.length === 6 ? `0${row.orderNr}` : row.orderNr;

        let pdfURLptSumitomo = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Fichas_Tecnicas_PO_${row.po.replace('/', '_')}/${row.workNr.replace('/', '_')}P${row.workPosition}.pdf`;
        let pdfURLenSumitomo = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Technical_Files_PO_${row.po.replace('/', '_')}/${row.workNr.replace('/', '_')}P${row.workPosition}.pdf`;
        let pdfURLfrSumitomo = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Fiches_Techniques_PO_${row.po.replace('/', '_')}/${row.workNr.replace('/', '_')}P${row.workPosition}.pdf`;

        let pdfURLpt = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Fichas_Tecnicas_PO_${row.po.replace('/', '_')}/${row.clientID}%20(${row.workNr.replace('/', '_')}P${row.workPosition}).pdf`;
        let pdfURLen = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Technical_Files_PO_${row.po.replace('/', '_')}/${row.clientID}%20(${row.workNr.replace('/', '_')}P${row.workPosition}).pdf`;
        let pdfURLfr = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Fiches_Techniques_PO_${row.po.replace('/', '_')}/${row.clientID}%20(${row.workNr.replace('/', '_')}P${row.workPosition}).pdf`;

        let pdfURLpt_old = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Fichas_Tecnicas_PO_${row.po.replace('/', '_')}/${row.clientID}.pdf`;
        let pdfURLen_old = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Technical_Files_PO_${row.po.replace('/', '_')}/${row.clientID}.pdf`;
        let pdfURLfr_old = `https://clients.dinefer.com/DocEnc/${ano}/${orderPath.replace('/', '_')}/Fiches_Techniques_PO_${row.po.replace('/', '_')}/${row.clientID}.pdf`;

        Promise.all([
            handlePromise(axios.get(pdfURLen, {headers: {'Authorization': authHeader}, mode: 'no-cors'}, )),
            handlePromise(axios.get(pdfURLfr, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLpt, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLen_old, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLfr_old, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLpt_old, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLenSumitomo, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLfrSumitomo, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
            handlePromise(axios.get(pdfURLptSumitomo, {headers: {'Authorization': authHeader}, mode: 'no-cors'})),
        ]).then((results) => { 
            console.log(results);
            results.forEach((result, index) => {
                //console.log(result.success);
                if (result.success === true) {
                    if (index === 0) { 
                        tecFileLanguages.push({name:'English', code: pdfURLen});
                    } else if (index === 1) { 
                        tecFileLanguages.push({name:'Français', code: pdfURLfr});
                    } else if (index === 2) {
                        tecFileLanguages.push({name:'Português', code: pdfURLpt});
                    } else if (index === 3) {
                        tecFileLanguages.push({name:'English', code: pdfURLen_old});
                    } else if (index === 4) { 
                        tecFileLanguages.push({name:'Français', code: pdfURLfr_old});
                    } else if (index === 5) { 
                        tecFileLanguages.push({name:'Português', code: pdfURLpt_old});
                    } else if (index === 6) {
                        tecFileLanguages.push({name:'English', code: pdfURLenSumitomo});
                    } else if (index === 7) { 
                        tecFileLanguages.push({name:'Français', code: pdfURLfrSumitomo});
                    } else if (index === 8) { 
                        tecFileLanguages.push({name:'Português', code: pdfURLptSumitomo});
                    }
                }
            });

            if (tecFileLanguages.length > 0) {
                setAvailableTecFiles(tecFileLanguages);
                setShowTecFileDownload(true);
            } else {
                toast.current.show({ severity: 'warn', summary: t('TTL_VIEW_TECHNICAL_FILE'), detail:t('MSG_UNABLE_LOAD_TEC_FILE'), life: 3000 });
            }
        }).catch((err) => {
            //console.log(err);
            toast.current.show({ severity: 'warn', summary: t('TTL_VIEW_TECHNICAL_FILE'), detail:t('MSG_UNABLE_LOAD_TEC_FILE'), life: 3000 });
        });
    }
    
    //UI - DETECT WINDOW RESIZE
    window.onresize = (event) =>{
        setRefreshData(!refreshData)
    };

    return (
        <Card 
            header={
                 <div className="h-4rem flex overflow-hidden">
                     <div className="flex-none flex align-items-center justify-content-center ml-3 mt-2">
                        <div className="mr-2 flex flex-wrap">
                            <Button 
                                label={t('BTN_SEARCHS')}  
                                icon={isFiltered ? "pi pi-filter-fill" : "pi pi-filter"}
                                raised
                                className='border-round-2xl'
                                onClick={(event) => {
                                    searchPanel.current.toggle(event);
                                    //setShowSearchBar(!isShowSearchBar);
                                    //window.localStorage.setItem('sbarclients', !isShowSearchBar); 
                                }} 
                            />
                        </div>
                        <div className="ml-3 flex flex-wrap">
                            <Button 
                                label={t('BTN_RELOAD_DATA')}  
                                icon="pi pi-refresh" 
                                raised 
                                className='border-round-2xl'
                                onClick={() => {
                                    setRefreshData(!refreshData); 
                                    setIsLoading(true);
                                }}
                            />
                       </div>
                     </div>
                 </div>
            } 
            style={{height: window.innerHeight-140}}
            className=" m-3 shadow-8 border-round-3xl"
        >
           
            {/* MANAGES THE TOASTS WITH THE MESSAGES */}
            <Toast ref={toast} position="top-center"/>

            {/* SEARCHES OVERLAY PANEL */}
            <OverlayPanel 
                ref={searchPanel} 
                showCloseIcon
                className='shadow-8 border-round-2xl'
            >
                <OrdersSearchs 
                    filters={searchFilters}
                    accessType={loggedUser.accessType}
                    plant={loggedUser.plant}
                    group={loggedUser.group}
                    setFilters={(e) => {
                        setSearchFilters({...searchFilters,  [e.target.id]:e.target.value})
                    }}
                    startSearch={() => {
                        searchFilters !== undefined && 
                        searchFilters !== null && 
                        setFilterStatus(
                            ((searchFilters.plant !== undefined && searchFilters.plant.name.trim().length > 0) || 
                            searchFilters.workNumber.trim().length > 0 || 
                            searchFilters.workPosition.trim().length > 0 || 
                            searchFilters.project.trim().length > 0 || 
                            searchFilters.po.trim().length > 0 || 
                            searchFilters.clientID.trim().length > 0 || 
                            searchFilters.nrWays.trim().length > 0 || 
                            (searchFilters.status !== undefined && searchFilters.status.name.trim().length > 0) || 
                            (searchFilters.colors !== undefined && searchFilters.colors.name.trim().length > 0))
                        );

                        setIsLoading(true);
                        setRefreshData(!refreshData);
                    }}
                    clearSearch={() => {
                        setIsLoading(true);
                        setFilterStatus(false);
                        setSearchFilters({
                            plant: {
                                name: loggedUser.accessType.toString().toLowerCase() === '2' ? loggedUser.plant : loggedUser.accessType.toString().toLowerCase() === '1' ? loggedUser.group : '',
                                code: loggedUser.accessType.toString().toLowerCase() === '2' ? loggedUser.plant : loggedUser.accessType.toString().toLowerCase() === '1' ? loggedUser.group : ''
                            },
                            workNumber: '',
                            workPosition: '',
                            project: '',
                            po: '',
                            clientID: '',
                            invoice: '',
                            nrWays: '',
                            status: '',
                            colors: '',
                        });

                        setRefreshData(!refreshData);
                    }}
                >
                </OrdersSearchs>
            </OverlayPanel>

            {/* SHOW AND MANAGES DATA TABLE INFORMATION */}
            <div className="card">
               
                <DataTable 
                    value={data} 
                    style={{marginTop: "-25px"}}
                    emptyMessage={t('TXT_NO_DATA_FOUND')}
                    size="small"
                    selectionMode={true}
                    selection={selectedData} 
                    onSelectionChange={(e) => setSelectedData(e.value)} 
                    dataKey="key" 
                    tableStyle={{ minWidth: '50rem' }}
                    scrollable
                    scrollHeight={window.innerHeight-335}
                    paginator 
                    rows={totalRows} 
                    rowsPerPageOptions={[25, 75, 100, 200]}
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={t("TXT_NR_RESULTS")}
                    footer={`${t('MSG_SHOWING_1')} ${data ? data.length : 0} ${t('MSG_SHOWING_ORDERS')}`}
                    onPage={(e) => {
                        setIsLoading(true);
                        setTotalRows(e.rows)
                    }}
                    expandedRows={expandedRows} 
                    onRowToggle={(e) => {
                        setExpandedRows(e.data);
                    }}
                    rowExpansionTemplate={expandedRowTemplate}
                    loading={isLoading}
                >
                    <Column expander={true} style={{ width: '4rem' }} />
                    <Column
                        header={t('TXT_TBL_TEC_FILE')}
                        body={(rowData) => {
                            return (
                                <Button 
                                    icon="pi pi-cloud-download" 
                                    style={{ 
                                        color: 'var(--surface-600)', 
                                    }}
                                    rounded 
                                    text 
                                    aria-label="Download" 
                                    onClick={() => {
                                        getTecFile(rowData);
                                    }}
                                />
                            )
                        }}
                        exportable={false}>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="clientID" 
                        header={t('TXT_TBL_CLIENT_ID')}
                        sortable>
                    </Column>
                    <Column 
                        header={t('TXT_TBL_COMPONENT')}
                        body={(rowData) => {
                            return (
                                <div className="block text-center">
                                    <Image src={getComponentImage(rowData.componentPath)} zoomSrc={getComponentImage(rowData.componentPath)} alt="" width="50" height="50" className="rounded-image-small" preview/>
                                </div>
                            )
                        }}
                        style={{ width: '8rem' }}
                        exportable={false}>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="nrWays" 
                        
                        header={t('TXT_TBL_NR_WAYS')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="color" 
                        header={t('TXT_TBL_COLOR')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="article" 
                        header={t('TXT_TBL_TYPE_CODE')}
                        sortable>
                    </Column>
                    {/*<Column
                        className='text-sm' 
                        field="articeTypeCode" 
                        header={t('TXT_TBL_TYPE_CODE')}
                        sortable>
                    </Column>
                     <Column
                        className='text-sm' 
                        field="orderNr" 
                        header={t('TXT_TBL_ORDER_NR')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="orderPos" 
                        header={t('TXT_TBL_POS')}
                        sortable>
                    </Column> */}
                    <Column
                        className='text-sm' 
                        field="workNr" 
                        header={t('TXT_TBL_WORK_NR')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="workPosition" 
                        header={t('TXT_TBL_POS')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="quantity" 
                        header={t('TXT_TBL_QTY')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="workStatus" 
                        header={t('TXT_TBL_STATUS')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="client" 
                        header={t('TXT_TBL_PLANT')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="project" 
                        header={t('TXT_TBL_PROJECT')}
                        sortable>
                    </Column>
                    {/* <Column
                        className='text-sm' 
                        field="ship" 
                        header={t('TXT_TBL_SHIP')}
                        sortable>
                    </Column> */}
                    <Column
                        className='text-sm' 
                        field="po" 
                        header={t('TXT_TBL_PO')}
                        sortable>
                    </Column>
                    <Column
                        className='text-sm' 
                        field="invoice" 
                        header={t('TXT_TBL_INVOICE')}
                        sortable>
                    </Column>
                </DataTable>
            </div>

            {/* SHOW TECHINCAL FILE DIALOG BOX*/}
            <Dialog 
                visible={isShowTecFileDownload} 
                position='top'
                style={{ width: '30rem' }} 
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                header={t('TTL_VIEW_TECHNICAL_FILE')}
                headerClassName='border-noround-bottom border-round-3xl'
                footerClassName='border-noround-top border-round-3xl'
                modal 
                draggable={false}
                closeOnEscape={true}
                className="p-fluid border-round-3xl" 
                footer={
                    <React.Fragment>
                        <Button 
                                label={t('BTN_VIEW')}  
                                icon="pi pi-eye"
                                className='w-9rem mr-2 border-round-2xl'
                                raised 
                                onClick={() => {
                                    if (selectedTecFile !== undefined) {
                                        setShowTecFileDownload(false);
                                        window.open(selectedTecFile.code);

                                        setSelectedTecFile(undefined);
                                        setAvailableTecFiles([]);
                                        setShowTecFileDownload(false);
                                    }
                                }} 
                            />
                    </React.Fragment>
                } 
                onHide={() => { 
                    setSelectedTecFile(undefined);
                    setAvailableTecFiles([]);
                    setShowTecFileDownload(false);
                }}
            >   
                <Fieldset legend={t('TXT_SELECT_LANGUAGE')} className="border-round-2xl">
                    <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-2">
                        <Dropdown 
                            value={selectedTecFile} 
                            onChange={(e) => setSelectedTecFile(e.value)} 
                            options={availableTecFiles} 
                            optionLabel="name" 
                            placeholder={t('CAPTION_SELECT_OPTION')}
                            panelClassName='border-round-xl border-noround-top shadow-3 p-2'
                            className="w-full border-round-xl" 
                        />
                    </div>   
                </Fieldset>
            </Dialog>
        </Card>
    );
        
}

export default ClientsPageSearchs;