import axios from 'axios';
import Cookies from "js-cookie";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const UserDetails = (props) => {
    const { t } = useTranslation();

    const [plantOptions, setPlantOptions] = useState(null);

    const [name, setName] = useState(props.loggedUser.name);
    const [username, setUsername] = useState(props.loggedUser.username);
    const [email, setEmail] = useState(props.loggedUser.email);
    const [plant, setPlant] = useState({code: props.loggedUser.plant, name: props.loggedUser.plant});
    const [group, setGroup] = useState(props.loggedUser.group);
    const [accessType, setAccessType] = useState({name: props.loggedUser.accessType === '0' ? 'ADMINISTRATOR': props.loggedUser.accessType === '3' ? 'ALL' : props.loggedUser.accessType === '1' ? 'GROUP' : 'PLANT', code: props.loggedUser.accessType});
    const [enabled, setEnabled] = useState(props.loggedUser.enabled);

    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');

    const [isSubmitted, setSubmitted] = useState(false);

    const AccessTypeOptions =  [
        { name: 'ADMINISTRATOR', code: '0' },
        { name: 'ALL', code: '3' },
        { name: 'GROUP', code: '1' },
        { name: 'PLANT', code: '2' },
    ];

    //EFFECT TO LOAD CLIENTS FROM API
    useEffect(() => {
        const controller = new AbortController();

        axios.get(
            `${process.env.REACT_APP_API_ADDRESS}/api/clients/findClients`,
            { 
                signal: controller.signal, 
                params: {
                    access: props.loggedUser.accessType, 
                    plant: props.loggedUser.plant,
                    group: props.loggedUser.group
                },
                headers: {
                    "x-auth-token":  JSON.stringify(Cookies.get("token"))
                }
            }
        ).then(result => {
            //console.log(result.data);
            setPlantOptions(result.data);

            if (props.accessType.toLowerCase() === '2') {
                setPlant({name: props.plant, code: props.plant});
            }
        }).catch(error => {
            if(axios.isCancel){
            } else {
                console.log(error)
            }
        })

        return () => {
            controller.abort()
        }
    }, [props]);

    //SAVES CLIENTS DATA
    const updateUser = () => {
        setSubmitted(true);
        
        if (name !== undefined && name.length > 0 &&
            username !== undefined && username.length > 0 &&
            plant !== undefined && plant.code.length > 0 &&
            group !== undefined && group.length > 0 &&
            accessType !== undefined && accessType.code.length > 0 &&
            password !== undefined && password.length >= 5 &&  password === repassword) {
            //console.log("SENDING DATA");
            axios.post(
                `${process.env.REACT_APP_API_ADDRESS}/api/clients/updateUserInfo`,
                {
                    name: name,
                    username: username,
                    password: password,
                    id: props.loggedUser.userID,
                },
                {
                    headers: {
                        "x-auth-token":  JSON.stringify(Cookies.get("token"))
                    }
                }
            ).then(result => {
                if (result.data.result === 0) {
                    props.onChangeData({
                        name: name,
                        username: username,
                        email: email,
                        plant: plant.code,
                        group: group,
                        accessType: accessType.code,
                        enabled: enabled,
                        exp: props.loggedUser.exp,
                        iat: props.loggedUser.iat,
                        password: password,
                        userID: props.loggedUser.userID,
                    })
                } 
            }).catch(error => {
                if(axios.isCancel){
                } else {
                    console.log(error)
                }
            })
        }
    };

    return (
        <div className="card ">
            <div className="m-0">

                <label htmlFor="name" className="text-sm text-left font-bold ml-2">{t('TXT_NAME')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (name === undefined || name.length === 0) && 
                        <small className="text-red-600">{t('TXT_NAME')+t('MSG_MUST_BE_FILLED')}</small>
                    }
                </div>
                <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                    <InputText id="name" className="w-full  border-round-xl" value={name} onChange={(e) => setName(e.target.value)} />  
                    
                </div>   

                <label htmlFor="username" className="text-sm text-left font-bold  ml-2">{t('TXT_USERNAME')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (username === undefined || username.length === 0) && 
                        <small className="text-red-600">{t('TXT_USERNAME')+t('MSG_MUST_BE_FILLED')}</small>
                    }
                </div>
                <div className="flex flex-wrap justify-content-center align-items-center mt-1 mb-3 mr-auto">
                    <InputText id="username" className="w-full border-round-xl" value={username} onChange={(e) => setUsername(e.target.value)} />  
                </div>   

                <label htmlFor="email" className="text-sm text-left font-bold ml-2">{t('TXT_EMAIL')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (email === undefined || email.length === 0) && 
                        <small className="text-red-600">{t('TXT_EMAIL')+t('MSG_MUST_BE_FILLED')}</small>
                    }
                </div>
                <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                    <InputText id="email" className="w-full border-round-xl" disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} />  
                </div>   

                <label htmlFor="plant" className="text-sm text-left font-bold ml-2">{t('TXT_PLANT')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (plant === undefined || plant.length === 0) && 
                        <small className="text-red-600">{t('TXT_PLANT')+t('MSG_MUST_BE_SELECTED')}</small>
                    }
                </div>
                <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                    <Dropdown 
                        value={plant} 
                        onChange={(e) => setPlant(e.value)} 
                        options={plantOptions} 
                        optionLabel="name" 
                        placeholder={t('CAPTION_SELECT_OPTION')}
                        disabled={true}
                        filter 
                        className="w-full border-round-xl" />
                </div>   

                <label htmlFor="group" className="text-sm text-left font-bold ml-2">{t('TXT_GROUP')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (group === undefined || group.length === 0) && 
                        <small className="text-red-600">{t('TXT_GROUP')+t('MSG_MUST_BE_FILLED')}</small>
                    }
                </div>
                <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                    <InputText id="group" className="w-full border-round-xl" disabled={true} value={group} onChange={(e) => setGroup(e.target.value)} />  
                </div>   

                <label htmlFor="accesstype" className="text-sm text-left font-bold ml-2">{t('TXT_ACCESS_TYPE')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (accessType === undefined || accessType.length === 0) && 
                        <small className="text-red-600">{t('TXT_ACCESS_TYPE')+t('MSG_MUST_BE_SELECTED')}</small>
                    }
                </div>
                <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                    <Dropdown 
                        value={accessType} 
                        onChange={(e) => setAccessType(e.value)} 
                        options={AccessTypeOptions} 
                        optionLabel="name" 
                        placeholder={t('CAPTION_SELECT_OPTION')}
                        disabled={true}
                        filter 
                        className="w-full border-round-xl" />
                </div>  

                <label htmlFor="status" className="text-sm text-left font-bold ml-2">{t('TXT_STATUS')}</label>
                <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                    <InputSwitch id="status" className="ml-2 mr-auto p-inputswitch-checked:bg-green-500" disabled={true} checked={enabled} onChange={(e) => setEnabled(e.value)} />  
                </div>  

                <label htmlFor="password" className="text-sm text-left font-bold ml-2">{t('TXT_PASSWORD')}</label>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        (password === undefined || password.length === 0) && 
                        <small className="text-red-600">{t('TXT_PASSWORD')+t('MSG_MUST_BE_FILLED')+' '}</small>
                    }
                    {
                        isSubmitted && 
                        (password === undefined || password.length < 5) && 
                        <small className="text-red-600">{ t('MSG_PASSWORD_AT_LEAST_FIVE_CHAR')}</small>
                    }
                </div>
                <div className="ml-2 mr-auto">
                    {
                        isSubmitted && 
                        password !== repassword && 
                        <small className="text-red-600">{t('TXT_PASSWORD')+t('MSG_DONT_MATCH')}</small>
                    }
                </div>
                <div className="flex mt-1 mb-1">
                    <Password id="password" className=""  value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                </div>
                <div className="flex mt-1 mb-5">
                    <Password id="repassword" className="border-round-xl" value={repassword} onChange={(e) => setRePassword(e.target.value)} toggleMask />
                </div>

                <div className="flex flex-wrap justify-content-center align-items-center gap-4 mt-1 mb-1">
                    <Button 
                        label={t('BTN_SAVE')}  
                        icon="pi pi-save"
                        className='w-8rem mr-2 border-round-2xl'
                        raised 
                        onClick={() => {updateUser();}} 
                    />

                    <Button 
                        label={t('BTN_CANCEL')}  
                        icon="pi pi-times"
                        className='w-8rem border-round-2xl'
                        raised 
                        onClick={props.onClose} 
                    />
                </div>
                
            </div> 
        </div> 
    );
}

export default UserDetails;