import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UsersSearchs = (props) => {
    const { t } = useTranslation();
    
    return (
        <div style={{ width: '18rem', height:'38rem'}} className="mt-3 ml-2 mr-2">
            <h2 className='mb-6' style={{marginTop: -15}}><i className="pi pi-search text-lg mr-3" />{t('TXT_SEARCH_OPTIONS')}</h2>
                  
            <div className="field">
                <FloatLabel>
                    <InputText 
                        id="name" 
                        value={props.filters.name} 
                        onChange={(e) => props.setFilters(e)} 
                        autoFocus 
                        className="p-inputtext-sm border-round-xl w-full"
                    >
                    </InputText>
                    <label className="font-bold">{t('TXT_NAME')}</label>
                </FloatLabel>
            </div>
            
            <div className="field mt-5">
                <FloatLabel>
                    <InputText 
                        id="userName" 
                        value={props.filters.userName} 
                        onChange={(e) => props.setFilters(e)} 
                        className="p-inputtext-sm border-round-xl w-full"
                    >
                    </InputText>
                    <label className="font-bold">{t('TXT_USERNAME')}</label>
                </FloatLabel>
            </div>

            <div className="field mt-5">
                <FloatLabel>    
                    <InputText 
                        id="email" 
                        value={props.filters.email} 
                        onChange={(e) => props.setFilters(e)} 
                        className="p-inputtext-sm border-round-xl w-full"
                    >
                    </InputText>
                    <label className="font-bold">{t('TXT_EMAIL')}</label>
                </FloatLabel>
            </div>

            <div className="field mt-5">
                <FloatLabel>    
                    <InputText 
                        id="group"
                        value={props.filters.group}
                        onChange={(e) => props.setFilters(e)}
                        className="p-inputtext-sm border-round-xl w-full"
                    >
                    </InputText>
                    <label className="font-bold">{t('TXT_GROUP')}</label>
                </FloatLabel>
            </div>

            <div className="field mt-5">
                <FloatLabel>    
                    <InputText 
                        id="plant"
                        value={props.filters.plant}
                        onChange={(e) => props.setFilters(e)}
                        className="p-inputtext-sm border-round-xl w-full"
                    >
                    </InputText>
                    <label className="font-bold">{t('TXT_PLANT')}</label>
                </FloatLabel>
            </div>

            <div className="flex flex-row mb-2 mt-5">
                <Fieldset legend={t('TXT_SITE_ACCESS')} className='w-2rem border-round-xl text-sm custom-fieldset pt-0' style={{ height: '90px' }}>
                    <TriStateCheckbox id="siteStatus" style={{marginLeft:-20, marginTop: -10}} variant="filled"  value={props.filters.siteStatus} onChange={(e) => props.setFilters(e)} />
                    <p style={{marginTop: -17, marginLeft: 7,}} className='text-xs w-3rem'>{props.filters.siteStatus == null ? "BOTH" : props.filters.siteStatus ? "ENABLED" : "DISABLED" }</p>
                </Fieldset>

                <Fieldset legend={t('TXT_MOBILE_APP_ACCESS')} className='ml-6 w-2rem border-round-xl text-sm custom-fieldset pt-0' style={{ height: '90px'}} >
                    <TriStateCheckbox id="status" style={{marginLeft:-20, marginTop: -10}} variant="filled" value={props.filters.status} onChange={(e) => props.setFilters(e)} />
                    <p style={{marginTop: -17, marginLeft: 7,}} className='text-xs w-3rem'>{props.filters.status == null ? "BOTH" : props.filters.status ? "ENABLED" : "DISABLED" }</p>
                </Fieldset>
            </div>

            <div className="field mt-5" style={{ textAlign: 'right' }}>
                <Button 
                    label={t('BTN_CLEAR')}  
                    icon="pi pi-delete-left" 
                    raised 
                    className='border-round-2xl mr-4'
                    onClick={() => {
                        props.clearSearch();
                    }}
                />

                <Button 
                    label={t('BTN_SEARCH')}  
                    icon="pi pi-search" 
                    raised 
                    className='border-round-2xl'
                    onClick={() => {
                        props.startSearch();
                    }}
                />  
            </div>
        </div>
    )
}

export default UsersSearchs