
import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from "validator";

// import logoImageDark from '../images/logos/reduced-logo-no-background-dark-mode.svg';
// import logoImage from '../images/logos/reduced-logo-no-background.svg';
import logoImageDark from '../images/logos/logo_dark.svg';
import logoImage from '../images/logos/logo_ligth.svg';

import appleLogoImage from '../images/apple_store_download.png';
import appleLogoImageDark from '../images/apple_store_download_dark.png';
import googleLogoImage from '../images/google_store_download.png';
import googleLogoImageDark from '../images/google_store_download_dark.png';

import frFlag from '../images/flags/france.png';
import ptFlag from '../images/flags/portugal.png';
import enFlag from '../images/flags/united-kingdom.png';

const UserLogin = ({loginEvent}) => {
    const [selectedLanguage, setSelectedLanguage] = useState(window.localStorage.getItem('language') !== null ? window.localStorage.getItem('language') : 'en');

    const [showRecoverPassword, setShowRecoverPassword] = useState(false);
    const [showNewAccount, setShowNewAccount] = useState(false);
    
    const [isSubmitted, setSubmitted] = useState(false);
    const [isUserNameUsed, setUserNameUsed] = useState(false);
    
    //LOGIN
    const username = useRef();
    const password = useRef();

    //PASSWORD RECOVER
    const email = useRef();

    //NEW ACCOUNT
    const newUserName = useRef();
    const newEmail = useRef();
    const newPlant = useRef();
    const newName = useRef();

    
    const toast = useRef(null);

    const { t, i18n } = useTranslation();

    // LOGIN HEADER 
    const header = (
        <>
            <div className='flex align-content-end flex-wrap'>
                <Avatar 
                    image={ptFlag} 
                    shape="circle" 
                    className='ml-auto mr-0 mt-3 p-overlay-badge' 
                    size='large'
                    onClick={() => {
                        window.localStorage.setItem('language', 'pt'); 
                        i18n.changeLanguage('pt');
                        setSelectedLanguage('pt')
                        window.localStorage.getItem('language')
                    }}
                >
                    {/* <Badge value="PT" severity={selectedLanguage === 'pt' ? "danger" : ""} /> */}
                    <Badge value="PT" className={selectedLanguage === 'pt' ? "bg-green-600" : "bg-gray-300"} />
                </Avatar>

                <Avatar 
                    image={enFlag} 
                    shape="circle" 
                    className='ml-4 mr-3 mt-3 p-overlay-badge' 
                    size='large'
                    onClick={() => {
                        window.localStorage.setItem('language', 'en'); 
                        i18n.changeLanguage('en');
                        setSelectedLanguage('en');
                        window.localStorage.getItem('language')
                    }}
                >
                    {/* <Badge value="EN" severity="danger" /> */}
                    <Badge value="EN" className={selectedLanguage === 'en' ? "bg-green-600" : "bg-gray-300"} />
                </Avatar>

                <Avatar 
                    image={frFlag} 
                    shape="circle" 
                    className='ml-2 mr-5 mt-3 p-overlay-badge' 
                    size='large'
                    onClick={() => {
                        window.localStorage.setItem('language', 'fr'); 
                        i18n.changeLanguage('fr');
                        setSelectedLanguage('fr');
                        window.localStorage.getItem('language')
                    }}
                >
                    {/* <Badge value="EN" severity="danger" /> */}
                    <Badge value="FR" className={selectedLanguage === 'fr' ? "bg-green-600" : "bg-gray-300"} />
                </Avatar>
            </div>
            
            <img className='ml-5 mr-auto mt-1 w-20rem' alt="Card" src={window.matchMedia('(prefers-color-scheme: dark)').matches ? logoImageDark: logoImage} height={280}/>
            <a href="https://apps.apple.com/pt/app/dinefer-app/id6505099496" target="_blank" rel="noreferrer">
                <img className='ml-6 mr-auto mb-1 w-9rem' alt="Card" src={window.matchMedia('(prefers-color-scheme: dark)').matches ? appleLogoImageDark: appleLogoImage} height={45}/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.mindsolutions.dineferapp" target="_blank" rel="noreferrer">
                <img className='ml-4 mr-auto mb-1 w-9rem' alt="Card" src={window.matchMedia('(prefers-color-scheme: dark)').matches ? googleLogoImageDark: googleLogoImage} height={45}/>
            </a>
        </>
    );

    // DETECT KEY PRESSED THE PRESS THE LOGIN BUTTON
    const keyPressedEvent = (e) => {
        if (e.key === 'Enter') 
            loginEvent(username.current.value, password.current.value);
    }
 
    return (
        <>  
            <Toast ref={toast} position="top-center"/>
            
            {/* LOGIN MAIN WINDOW */}
            <div className="card flex justify-content-center align-content-center mt-8 mb-5">
                <Card header={header} className="md:w-25rem shadow-8 border-round-3xl">
                    <div className="m-0">
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1">
                            <span className="p-float-label">    
                                <InputText id="username" ref={username} onKeyDownCapture={e => keyPressedEvent(e)} type="text" autoFocus className="w-20rem border-round-xl"/> 
                                <label className="font-bold">{t('TXT_USERNAME')}</label>
                            </span>
                        </div>
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-5">
                            <span className="p-float-label">    
                                <InputText  ref={password} onKeyDownCapture={e => keyPressedEvent(e)} id="password" type="password" className="w-20rem border-round-xl" />
                                <label className="font-bold">{t('TXT_PASSWORD')}</label>
                            </span>
                        </div>
                        <div className="flex align-items-center justify-content-center h-4rem font-bold border-round m-2 mt-5">
                            <Button 
                                label={t('BTN_LOGIN')}  
                                icon="pi pi-user"
                                className='w-10rem border-round-2xl'
                                raised 
                                onClick={() => {
                                    loginEvent(username.current.value, password.current.value);
                                }} 
                            />
                        </div> 
                        <div className="flex align-items-center justify-content-center font-bold border-round mt-1"> 
                            <Button 
                                label={t('BTN_CREATE_ACCOUNT')} 
                                style={{ 
                                    color: 'var(--surface-600)'
                                }}
                                className='border-round-2xl text-sm'
                                link 
                                onClick={() => { setShowNewAccount(true)} }
                            />
                            
                            <span>|</span>

                            <Button 
                                label={t('BTN_RECOVER_PASSWORD')} 
                                style={{ 
                                    color: 'var(--surface-600)'
                                }}
                                className='border-round-2xl text-sm'
                                link 
                                onClick={() => { setShowRecoverPassword(true)} }
                            />
                        </div>
                    </div>
                </Card>
            </div>

            {/* RECOVER PASSWORD DIALOG */}
            <Dialog 
                visible={showRecoverPassword} 
                position='top'
                style={{ width: '30rem'}} 
                headerClassName='border-noround-bottom border-round-3xl'
                footerClassName='border-noround-top border-round-3xl'
                //contentStyle={{ borderRadius: '20px' }} 
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                header={t('TXT_RECOVER_PASWORD')}
                modal 
                draggable={false}
                closeOnEscape={true}
                className="p-fluid border-round-3xl" 
                footer={
                    <React.Fragment>
                        <Button 
                                label={t('BTN_RECOVER')}  
                                icon="pi pi-check-square"
                                className='w-9rem mr-2 border-round-2xl'
                                raised 
                                onClick={() => {
                                   if (email !== undefined && validator.isEmail(email.current.value)) {
                                        axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/clients/recover`, {params: {email: email.current.value}})
                                        .then(result => {
                                            //console.log(result.data);
                                            if (result.data.result === 0) {
                                                setShowRecoverPassword(false);
                                                email.current.value = "";
                                                toast.current.show({ severity: 'success', summary: t("TXT_RECOVER_PASWORD"), detail: t("TXT_RECOVER_SUCCESS"), life: 3000 });
                                            } else if (result.data.result === -1) {
                                                toast.current.show({ severity: 'error', summary: t("TXT_RECOVER_PASWORD"), detail: result.data.details, life: 3000 });
                                            } else if (result.data.result === -2) {
                                                toast.current.show({ severity: 'error', summary: t("TXT_RECOVER_PASWORD"), detail: t("TXT_NOT_FOUND_EMAIL"), life: 3000 });
                                            } else if (result.data.result === -3) {
                                                toast.current.show({ severity: 'error', summary: t("TXT_RECOVER_PASWORD"), detail: t("TXT_NOT_FOUND_EMAIL"), life: 3000 });
                                            }
                                        })
                                        .catch(error => {
                                            if(axios.isCancel){
                                            } else {
                                                console.log(error)
                                            }
                                        })
                                    } else {
                                        toast.current.show({ severity: 'error', summary: t("TXT_RECOVER_PASWORD"), detail: t("TXT_INVALID_EMAIL"), life: 3000 });
                                    } 
                                }} 
                            />
                    </React.Fragment>
                } 
                onHide={() => { 
                    setShowRecoverPassword(false);
                    email.current.value = "";
                }}
            >   
                <Fieldset legend={t('TXT_EMAIL')} className="border-round-2xl">
                    <p className="m-0">
                        {t('TXT_RECOVER_PASWORD_INFO')}
                    </p>
                    <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-2">
                        <InputText keyfilter="email"  id="email" ref={email} className="border-round-xl"/>  
                    </div>   
                </Fieldset>
            </Dialog>

            {/* CREATE NEW USER DIALOG */}
            <Dialog 
                visible={showNewAccount} 
                position='top'
                style={{ width: '26rem'}} 
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                header={t('TXT_CREATE_NEW_ACCOUNT')}
                headerClassName='border-noround-bottom border-round-3xl'
                footerClassName='border-noround-top border-round-3xl'
                modal 
                draggable={false}
                closeOnEscape={true}
                className="p-fluid border-round-3xl" 
                footer={
                    <React.Fragment>
                        <Button 
                                label={t('BTN_CREATE')}  
                                icon="pi pi-check-square"
                                className='w-9rem mr-2 border-round-2xl'
                                raised 
                                onClick={() => {
                                    setSubmitted(true);
                                    setUserNameUsed(false);

                                    if (newName.current.value !== undefined && newName.current.value.length > 0 &&
                                        newUserName.current.value !== undefined && newUserName.current.value.length > 0 &&
                                        newEmail.current.value !== undefined && newEmail.current.value.length > 0 &&
                                        newPlant.current.value !== undefined && newPlant.current.value.length > 0 ) {

                                        axios.get(`${process.env.REACT_APP_API_ADDRESS}/api/clients/checkLogin`, {params: {uname: newUserName.current.value}})
                                        .then(result => {
                                            if (result.data.result === 0) {
                                                axios.post(
                                                    `${process.env.REACT_APP_API_ADDRESS}/api/clients/createUser`,
                                                    {
                                                        name: newName.current.value,
                                                        username: newUserName.current.value,
                                                        email: newEmail.current.value,
                                                        plant: newPlant.current.value,
                                                        group: '',
                                                        acesstype: '2',
                                                        status: '0',
                                                        password: '',//btoa(Math.random()).slice(0, 8),
                                                        userid: -1,
                                                    }
                                                )
                                                .then(insertResult => {
                                                    //console.log(insertResult)
                                                    if (insertResult.data.result === 0) {
                                                        setShowNewAccount(false);
                                                        setSubmitted(false);
                                                        setUserNameUsed(false);

                                                        newName.current.value = "";
                                                        newUserName.current.value = "";
                                                        newEmail.current.value = "";
                                                        newPlant.current.value = "";

                                                        toast.current.show({ severity: 'success', summary: t("TXT_CREATE_NEW_ACCOUNT"), detail: t('TXT_ACCOUNT_CREATED_SUCCESS'), life: 3000 });
                                                    } else {
                                                        toast.current.show({ severity: 'error', summary: t("TXT_CREATE_NEW_ACCOUNT"), detail: insertResult.data.details, life: 3000 });
                                                    }
                                                })
                                                .catch(error => {
                                                    if(axios.isCancel){
                                                    } else {
                                                        console.log(error)
                                                    }
                                                })
                                            } else if (result.data.result === -2) {
                                                setUserNameUsed(true);
                                            } else {
                                                toast.current.show({ severity: 'error', summary: t("TXT_CREATE_NEW_ACCOUNT"), detail: result.data.details, life: 3000 });
                                            }
                                        })
                                        .catch(error => {
                                            if(axios.isCancel){
                                            } else {
                                                console.log(error)
                                            }
                                        })
                                    }
                                }} 
                            />
                    </React.Fragment>
                } 
                onHide={() => { 
                    setShowNewAccount(false);
                    setSubmitted(false);
                    setUserNameUsed(false);

                    newName.current.value = "";
                    newUserName.current.value = "";
                    newEmail.current.value = "";
                    newPlant.current.value = "";
                }}
            >   
                    <label htmlFor="name" className="text-sm text-left ml-2">{t('TXT_NAME')}</label>
                    <div className="ml-2 mr-auto">
                        {
                            isSubmitted && 
                            (newName.current.value === undefined || newName.current.value.length === 0) && 
                            <small className="text-red-600">{t('TXT_NAME')+t('MSG_MUST_BE_FILLED')}</small>
                        }
                    </div>
                    <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                        <InputText id="name" className="w-full border-round-xl" ref={newName}/*value={newName} onChange={(e) => setNewName(e.target.value)}*//>  
                    </div>   

                    <label htmlFor="username" className="text-sm text-left ml-2">{t('TXT_USERNAME')}</label>
                    <div className="ml-2 mr-auto">
                        {
                            isSubmitted && 
                            (newUserName.current.value === undefined || newUserName.current.value.length === 0) && 
                            <small className="text-red-600">{t('TXT_USERNAME')+t('MSG_MUST_BE_FILLED')}</small>
                        }
                    </div>
                    <div className="ml-2 mr-auto">
                        {
                            isSubmitted && isUserNameUsed && 
                            <small className="text-red-600">{t('TXT_USERNAME')+t('TXT_USERNAME_IN_USE')}</small>
                        }
                    </div>
                    <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                        <InputText id="username" className="w-full border-round-xl" ref={newUserName}/*value={newUserName} onChange={(e) => setNewUserName(e.target.value)}*//>  
                    </div>   

                    <label htmlFor="email" className="text-sm text-left ml-2">{t('TXT_EMAIL')}</label>
                    <div className="ml-2 mr-auto">
                        {
                            isSubmitted && 
                            (newEmail.current.value === undefined || newEmail.current.value.length === 0) && 
                            <small className="text-red-600">{t('TXT_EMAIL')+t('MSG_MUST_BE_FILLED')}</small>
                        }
                    </div>
                    <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                        <InputText id="email" className="w-full border-round-xl" ref={newEmail}/*value={newEmail} onChange={(e) => setNewEmail(e.target.value)}*/ />  
                    </div>

                    <label htmlFor="plant" className="text-sm text-left ml-2">{t('TXT_PLANT')}</label>
                    <div className="ml-2 mr-auto">
                        {
                            isSubmitted && 
                            (newPlant.current.value === undefined || newPlant.current.value.length === 0) && 
                            <small className="text-red-600">{t('TXT_PLANT')+t('MSG_MUST_BE_FILLED')}</small>
                        }
                    </div>
                    <div className="flex flex-wrap justify-content-center align-items-center gap-2 mt-1 mb-3">
                        <InputText id="plant" className="w-full border-round-xl" ref={newPlant}/*value={newPlant} onChange={(e) => setNewPlant(e.target.value)}*/ />  
                    </div>
            </Dialog>
        </>                 
    )
}

export default UserLogin;