import axios from 'axios';
import Cookies from "js-cookie";
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { FloatLabel } from 'primereact/floatlabel';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

//import '../styles/customStyle.css';
import UsersSearchs from './UsersSearchs';

const ManageUsers = (props) => {
   const { t } = useTranslation();

   const [data, setData] = useState([]);
   const [totalDataRow, setTotalDataRows] = useState(0);
   const [isSubmitted, setSubmitted] = useState(false);
   const [isFiltered, setFilterStatus] = useState(false);

   const [isUserNameUsed, setUserNameUsed] = useState(false);

   const [filters, setFilters] = useState({name:'', userName:'', email:'', group:'', plant:'', status: null, siteStatus: null}); 

   const [totalRows, setTotalRows] = useState(25);
   const [refreshData, setRefreshData] = useState(false);
   
   const [isLoading, setIsLoading] = useState(true);

   const [showEditUser, setShowEditUser] = useState({show: false, user: undefined});   

   const [plantOptions, setPlantOptions] = useState(null);

   const toast = useRef(null);
   const btSave = useRef(null);
   const searchPanel = useRef(null);

   const accesstype = [
      { name: 'Administrator', code: 0 },
      { name: 'Group', code: 1 },
      { name: 'Plant', code: 2 },
      { name: 'Dinefer', code: 3 },
      { name: 'Dinefer - Devices', code: 4 },
      { name: 'Dinefer - Tags', code: 5 }
   ];

   //EFFECT TO LOAD USERS FROM API
   useEffect(() => {
      const controller = new AbortController();

      axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/api/users/findUsers`,
          { 
              signal: controller.signal, 
              params: {
                  totalRows: totalRows, 
                  filters: JSON.stringify(filters)
              },
               headers: {
                  "x-auth-token":  JSON.stringify(Cookies.get("token"))
               }
          }
      )
      .then(result => {
         setIsLoading(false);
         setData(result.data.usersList);
         setTotalDataRows(result.data.total);
      })
      .catch(error => {
          if(axios.isCancel){
          } else {
              console.log(error)
          }
      })

      return () => {
          controller.abort()
      }
   // eslint-disable-next-line
   }, [totalRows, 
      refreshData, 
      //filters,
      // filters.name, 
      // filters.userName,
      // filters.email,
      // filters.group,
      // filters.plant
   ]);

   //EFFECT TO LOAD CLIENTS FROM API
   useEffect(() => {
      const controller = new AbortController();

      axios.get(
         `${process.env.REACT_APP_API_ADDRESS}/api/clients/findAllClients`,
         { 
            signal: controller.signal,
            headers: {
               "x-auth-token":  JSON.stringify(Cookies.get("token"))
            }
         }
      ).then(result => {
         setPlantOptions(result.data);
      }).catch(error => {
          if(axios.isCancel){
          } else {
              console.log(error)
          }
      })

      return () => {
          controller.abort()
      }
  }, []);

   //CREATES THE CHIP CONTENT WITH THE ACCESS DATA
   const getAccessChipContent = (data) => { 
      let name = 'Admin';
      if (data.accessType === 1) {
         name = 'Group';
      } else if (data.accessType === 2) {
         name = 'Plant';
      } else if (data.accessType === 3) {
         name = 'Dinefer';
      } else if (data.accessType === 4) {
         name = 'Devices';
      } else if (data.accessType === 5) {
         name = 'Tags';
      }


      return (
         <span className=" w-3rem h-2rem flex align-items-center justify-content-center text-sm">{name}</span>
      );
   }

   //RETURNS THE ACCESS TYPE OPTION ACCORDING TO THE USER DATA
   const getSelectedAccessOption = (data) => {
      if (data === 0) {
        return { name: 'Administrator', code: 0 };
      } else if (data === 1) {
        return { name: 'Group', code: 1 };
      } else if (data === 2) {
         return { name: 'Plant', code: 2 };
      } else if (data === 3) {
         return  { name: 'Dinefer', code: 3 };
      } else if (data === 4) {
         return { name: 'Dinefer - Devices', code: 4 };
      } else if (data === 5) {
        return { name: 'Dinefer - Tags', code: 5 };
      }
   }

   //RETURNS THE PLANT OPTION ACCORDING TO THE USER DATA
   const getSelectedPlantOption = (data) => {
      let found = false;
      if (plantOptions !== null) {
         for (let i = 0; i < plantOptions.length && !found; i++) 
         if (plantOptions[i].name.toLowerCase().trim() === data.toLowerCase().trim()) {
            found = true;
            return plantOptions[i];
         }
      } 

      return null;
   }

   //MAKES DE ASSOCIATION BETWEEN THE DIALOG FIELDS AND THE CURRENT CLIENT CONST VARIABLE
   const onInputChange = (e, field) => {
      if (e !== undefined && e.target !== undefined && e.target.value !== undefined) {
         let val = e.target !== undefined ? (e.target && e.target.value) || '' : e.value !== null ? e.value: '';

         let _editing = { ...showEditUser.user };

         if (field.toLowerCase().includes('access')) {
            _editing[`${field}`] = val.code;
         } else if(field.toLowerCase().includes('plant')) {
            _editing[`clientID`] = val.code;
            _editing[`${field}`] = val.name;
         } else {
            _editing[`${field}`] = val;
         }
         
         setShowEditUser({show: true, user: _editing});
      }
   };

   //SAVE THE CHANGES ON THE PROPOSAL POSITION
   const saveClient = () => {
      setSubmitted(true);
      btSave.current.disabled = true;

      if (showEditUser.user !== null && showEditUser.user !== undefined && 
         showEditUser.user.userName !== null && showEditUser.user.userName !== undefined && showEditUser.user.userName.trim().length > 0 &&
         showEditUser.user.userLogin !== null && showEditUser.user.userLogin !== undefined && showEditUser.user.userLogin.trim().length > 0 &&
         showEditUser.user.email !== null && showEditUser.user.email !== undefined && showEditUser.user.email.trim().length > 0 &&
         showEditUser.user.groupName !== null && showEditUser.user.groupName !== undefined && showEditUser.user.groupName.trim().length > 0) {
         
            const controller = new AbortController();

            axios.get(
               `${process.env.REACT_APP_API_ADDRESS}/api/clients/checkLoginBeforeSave`,
               { 
                  signal: controller.signal, 
                  params: {
                     uname: showEditUser.user.userLogin,
                     id: showEditUser.user.id
                  }
               }
            )
            .then(result => {
                  if (result.data.result === 0) {
                     axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/clients/updateUser`, 
                        { 
                           clientData: JSON.stringify(showEditUser.user)
                        },
                        {
                           headers: {
                              "x-auth-token":  JSON.stringify(Cookies.get("token"))
                           }
                        }
                     ).then(result => {
                        if (result.data.result === 0) {
                           setSubmitted(false);
                           setShowEditUser({show: false, user: undefined});
                           setRefreshData(!refreshData);
                        } else {
                           toast.current.show({ severity: 'error', summary: t('TTL_EDITING_USER'), detail: result.data.result === -1 ? t(result.data.error) : result.data.error, life: 3000 });
                        }

                        btSave.current.disabled = false;
                        
                     }).catch(error => {
                        btSave.current.disabled = false;

                        if(axios.isCancel){
                        } else {
                           console.log(error)
                        }
                     });  
                  } else {
                      btSave.current.disabled = false;
                      setUserNameUsed(true);
                  }
            })
            .catch(error => {
                  btSave.current.disabled = false;
                  
                  if(axios.isCancel){
                  } else {
                     console.log(error)
                  }
                  
            })
      
            return () => {
                  controller.abort()
            }   

                
      } else {
         btSave.current.disabled = false;
      }
   }

   //CONFIRMS REMOVE USER
   const removeClient = (event, userID) => {
      confirmPopup({
          target: event.currentTarget,
          message: t('MSG_REMOVE_USER'),
          icon: 'pi pi-info-circle',
          defaultFocus: 'reject',
          acceptClassName: 'p-button-danger border-round-2xl',
          rejectClassName: 'border-round-2xl p-button-outlined',
          acceptLabel: t('BTN_REMOVE'),
          rejectLabel: t('BTN_CANCEL'),
          className:'border-round-2xl',
          accept: () => {
            axios.post(
               `${process.env.REACT_APP_API_ADDRESS}/api/clients/removeUser`, 
               {
                  userid: userID
               },
               {
                  headers: {
                      "x-auth-token":  JSON.stringify(Cookies.get("token"))
                  }
               }
            ).then((result => {
               if (result.data.result === 0) {
                  setRefreshData(!refreshData);
                  toast.current.show({ severity: 'success', summary: t('TTL_DELETE_USER'), detail: t('MSG_REMOVE_USER_SUCCESS'), life: 3000 });
               } else {
                  toast.current.show({ severity: 'error', summary: t('TTL_DELETE_USER'), detail: result.data.result === -1 ? t(result.data.error) : result.data.error, life: 3000 });
               }
            }));
          }
      });
   };

   //CONFIRMS REMOVE USER
   const confirmPasswordReset = (event) => {
      confirmPopup({
         target: event.currentTarget,
         message: t('MSG_CONFIRM_RESET_PASSWORD'),
         icon: 'pi pi-info-circle',
         defaultFocus: 'accept',
         className: 'border-round-2xl',
         acceptClassName: 'p-button-primary border-round-2xl',
         rejectClassName: 'border-round-2xl p-button-outlined',
         acceptLabel: t('BTN_YES'),
         rejectLabel: t('BTN_NO'),
          accept: () => {
            axios.get(
               `${process.env.REACT_APP_API_ADDRESS}/api/clients/resetPassword`,
               { 
                  params: {
                     id: showEditUser.user.id, 
                  },
                  headers: {
                     "x-auth-token":  JSON.stringify(Cookies.get("token"))
                  }
               }
            )
            .then(result => {
                  if(result.data.result === 0) {
                     toast.current.show({ severity: 'success', summary: t('BTN_RESET_PASSWORD'), detail: t('MSG_PASSWORD_SUCCESS_RESET'), life: 3000 });
                  } else {
                     toast.current.show({ severity: 'error', summary: t('BTN_RESET_PASSWORD'), detail: result.data.result === -1 ? t(result.data.error) : result.data.error, life: 3000 });
                  }
            });
          }
      });
   };

   //UI - DATATABLE COLUMN TEMPLATE
   const columnTemplate = (value) => {
      return (
          <div style={{overflowY: 'auto' }}>
              {value}
          </div>
      );
  };

   //UI - DETECT WINDOW RESIZE
   window.onresize = (event) =>{
      setRefreshData(!refreshData)
   };

   return (
      <div className="card">
         {/* MANAGES THE TOASTS WITH THE MESSAGES */}
         <Toast ref={toast} />

         {/* REMOVE USER CONFIRMATON POPUP*/}
         <ConfirmPopup />

         {/* SEARCHES OVERLAY PANEL */}
         <OverlayPanel 
            ref={searchPanel} 
            showCloseIcon
            className='shadow-8 border-round-2xl'
         >
            <UsersSearchs 
               filters={filters}
               setFilters={(e) => {
                     setFilters({...filters,  [e.target.id]:e.target.value})
               }}
               startSearch={() => {
                  setFilterStatus(
                     (filters.name.trim().length > 0 || 
                     filters.userName.trim().length > 0 || 
                     filters.email.trim().length > 0 || 
                     filters.group.trim().length > 0 || 
                     filters.plant.trim().length > 0 ||
                     filters.status !== null ||
                     filters.siteStatus !== null)
                  );

                  setIsLoading(true);
                  setRefreshData(!refreshData);
               }}
               clearSearch={() => {
                  setIsLoading(true);
                  setFilterStatus(false);
                  setFilters({name:'', userName:'', email:'', group:'', plant:'', status: null, siteStatus: null});

                  setRefreshData(!refreshData);
              }}
            >
            </UsersSearchs>
         </OverlayPanel>

         {/* SHOWS THE SEARCH AND REFRESH BUTTONS */}
         <div className='mb-4 mt-1'>
            <Button 
               label={t('BTN_SEARCHS')}  
               icon={isFiltered ? "pi pi-filter-fill" : "pi pi-filter"}
               raised 
               className='border-round-2xl'
               onClick={(event) => {
                  searchPanel.current.toggle(event);
               }} 
            />
            <Button 
               label={t('BTN_RELOAD_DATA')}  
               icon="pi pi-refresh" 
               raised 
               className='border-round-2xl ml-4'
               onClick={() => {
                   setRefreshData(!refreshData); 
                   setIsLoading(true);
               }}
            />
         </div>

         {/* SHOW AND MANAGES DATA TABLE INFORMATION */}
         <DataTable 
            value={data} 
            emptyMessage={t('TXT_NO_DATA_FOUND')}
            size="small"
            selectionMode={true}
            dataKey="id" 
            tableStyle={{ minWidth: '50rem' }}
            scrollable
            scrollHeight={window.innerHeight-315}
            paginator 
            rows={totalRows} 
            rowsPerPageOptions={[25, 75, 100, 200]}
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={t("TXT_NR_RESULTS")}
            onPage={(e) => {
               setIsLoading(true);
               setTotalRows(e.rows)
            }}    
            footer={`${t('MSG_SHOWING_1')} ${totalRows > totalDataRow ? totalDataRow : totalRows} ${t('MSG_SHOWING_2')} ${totalDataRow ? totalDataRow : 0} ${t('MSG_SHOWING_USERS')}`}
            loading={isLoading}
         >
            <Column
               header=''
               body={(rowData) => {
                     return (
                        <>
                           <Button 
                              icon="pi pi-pencil" 
                              style={{ 
                                    color: 'var(--surface-600)', 
                              }}
                              rounded 
                              text 
                              aria-label="Edit" 
                              onClick={() => {
                                    setShowEditUser({show: true, user: rowData});
                              }}
                           />

                           <Button 
                              icon="pi pi-trash" 
                              style={{ 
                                    color: 'var(--surface-600)', 
                              }}
                              rounded 
                              text 
                              aria-label="Remove" 
                              onClick={e => removeClient(e, rowData.id)}
                           />

                        </>
                     )
               }}
               exportable={false}>
            </Column>
            <Column
               header=''
               body={(rowData) => {
                  return (
                     rowData.isNew ? <Badge value="New" className='mb-2' severity="info"></Badge> : null
                  )
               }}
               exportable={false}>
            </Column>
            <Column
               className='text-sm' 
               field="userName" 
               header={t('TXT_NAME')}
               sortable
               body={(rowData) =>columnTemplate(rowData.userName)} 
               style={{ maxWidth: '220px' }} 
            >
            </Column>
            <Column
               className='text-sm' 
               field="userLogin" 
               header={t('TXT_USERNAME')}
               sortable
               body={(rowData) =>columnTemplate(rowData.userLogin)} 
               style={{ maxWidth: '220px' }} 
            >
            </Column>
            <Column
               className='text-sm' 
               field="email" 
               header={t('TXT_EMAIL')}
               sortable
               body={(rowData) =>columnTemplate(rowData.email)} 
               style={{ maxWidth: '250px' }} 
            >
            </Column>
            <Column
               header={t('TXT_ACCESS_TYPE_SHORT')}
               body={(rowData) => {
                  return (
                     <Chip className="shadow-4" template={getAccessChipContent(rowData)} />
                  )
               }}
               exportable={false}>
            </Column>
            <Column
               className='text-sm' 
               field="groupName" 
               header={t('TXT_GROUP')}
               sortable
               body={(rowData) =>columnTemplate(rowData.groupName)} 
               style={{ maxWidth: '220px' }} 
            >
            </Column>
            <Column 
               className='text-sm' 
               field="plantName" 
               header={t('TXT_PLANT')}
               sortable
               body={(rowData) =>columnTemplate(rowData.plantName)} 
               style={{ maxWidth: '220px' }} 
            >
            </Column>
            <Column
               header={t('TXT_SITE_ACCESS')}
               body={(rowData) => {
                  return (
                     rowData.siteStatus ? 
                     <Avatar icon="pi pi-check" size="small" shape="circle" style={{ backgroundColor: '#73c261', color: '#ffffff' }} className="shadow-4"/>:
                     <Avatar icon="pi pi-times" size="small" shape="circle" style={{ backgroundColor: '#f28979', color: '#ffffff' }} className="shadow-4"/>
                  )
            }}
            exportable={false}>
            </Column>
            <Column
               header={t('TXT_MOBILE_APP_ACCESS')}
               className='w-3rem'
               body={(rowData) => {
                     return (
                        rowData.status ? 
                        <Avatar icon="pi pi-check" size="small" shape="circle" style={{ backgroundColor: '#73c261', color: '#ffffff' }} className="shadow-4"/>:
                        <Avatar icon="pi pi-times" size="small" shape="circle" style={{ backgroundColor: '#f28979', color: '#ffffff' }} className="shadow-4"/>
                     )
               }}
               exportable={false}>
            </Column>
         </DataTable>

          {/* SHOWS EDIT DIALOG */}
          <Dialog 
                visible={showEditUser.show} 
                position='top'
                style={{ width: '30rem' }} 
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
                header={t('TTL_EDITING_USER')}
                headerClassName='border-noround-bottom border-round-3xl'
                footerClassName='border-noround-top border-round-3xl'
                modal 
                draggable={false}
                closeOnEscape={false}
                className="p-fluid border-round-3xl" 
                footer={
                    <React.Fragment>
                        <Button 
                           label={t('BTN_SAVE')}  
                           icon="pi pi-save"
                           ref={btSave}
                           className='w-8rem mr-2 border-round-2xl mr-3'
                           raised 
                           onClick={() => {
                              setUserNameUsed(false);
                              saveClient();
                           }} 
                        />

                        <Button 
                           label={t('BTN_CANCEL')}  
                           icon="pi pi-times-circle"
                           className='w-8rem mr-2 border-round-2xl'
                           raised 
                           onClick={() => {
                              setUserNameUsed(false);
                              setShowEditUser({show: false, user: undefined});
                           }} 
                        />
                    </React.Fragment>
                } 
                onHide={() => { 
                     setShowEditUser({show: false, user: undefined});
                 }}
            >   
                  <FloatLabel className="mt-4">
                     <InputText 
                        className='w-full border-round-xl text-base' 
                        id="username" 
                        value={showEditUser.user !== undefined ? showEditUser.user.userName : ''}
                        onChange={(e) => onInputChange(e, 'userName')} 
                     />
                     <label>{t('TXT_NAME')}</label>
                  </FloatLabel>
                  {isSubmitted && showEditUser.user !== undefined && showEditUser.user !== null &&  showEditUser.user.userName.trim().length <= 0 && <small className="p-error">{t('TXT_NAME')+t('MSG_MUST_BE_FILLED')}</small>}

                  <div className='h-1rem'/>
                  
                  <FloatLabel className="mt-4">
                     <InputText 
                        className='w-full border-round-xl text-base' 
                        id="login" 
                        value={showEditUser.user !== undefined ? showEditUser.user.userLogin: ''}
                        onChange={(e) => onInputChange(e, 'userLogin')} 
                     />
                     <label>{t('TXT_USERNAME')}</label>
                  </FloatLabel>
                  {isSubmitted && showEditUser.user !== undefined && showEditUser.user !== null &&  showEditUser.user.userLogin.trim().length <= 0 && <small className="p-error">{t('TXT_USERNAME')+t('MSG_MUST_BE_FILLED')}</small>}
                  <div className="ml-2 mr-auto">
                     {
                           isSubmitted && isUserNameUsed && 
                           <small className="text-red-600">{t('TXT_USERNAME')+t('TXT_USERNAME_IN_USE')}</small>
                     }
                  </div>
                    
                  <div className="flex justify-content-center flex-wrap">
                     <Button 
                        label={t('BTN_RESET_PASSWORD')}  
                        icon="pi pi-key"
                        className='w-16rem mt-2 ml-auto mr-auto border-round-2xl'
                        raised 
                        onClick={e => confirmPasswordReset(e)} 
                     />
                  </div>

                  <div className='h-1rem'/>

                  <FloatLabel className="mt-4">
                     <InputText 
                        className='w-full border-round-xl text-base' 
                        id="email" 
                        value={showEditUser.user !== undefined ? showEditUser.user.email: ''}
                        onChange={(e) => onInputChange(e, 'email')} 
                     />
                     <label>{t('TXT_EMAIL')}</label>
                  </FloatLabel>
                  {isSubmitted && showEditUser.user !== undefined && showEditUser.user !== null &&  showEditUser.user.email.trim().length <= 0 && <small className="p-error">{t('TXT_USERNAME')+t('MSG_MUST_BE_FILLED')}</small>}

                  <div className='h-1rem'/>

                  <FloatLabel className="mt-4">
                     <Dropdown 
                        panelClassName='border-round-xl border-noround-top shadow-3 p-2'
                        className='w-full border-round-xl text-base' 
                        id="access"  
                        value={showEditUser.user !== undefined ? getSelectedAccessOption(showEditUser.user.accessType) : ''} 
                        onChange={(e) => onInputChange(e, 'accessType')} 
                        options={accesstype} 
                        optionLabel='name' 
                        checkmark={true}  
                        highlightOnSelect={true} 
                     />
                     <label>{t('TXT_ACCESS_TYPE')}</label>
                  </FloatLabel>

                  <div className='h-1rem'/>

                  <FloatLabel className="mt-4">
                     <InputText 
                        className='w-full border-round-xl text-base' 
                        id="group" 
                        value={showEditUser.user !== undefined ? showEditUser.user.groupName : ''}
                        onChange={(e) => onInputChange(e, 'groupName')} 
                     />
                     <label>{t('TXT_GROUP')}</label>
                  </FloatLabel>
                  {isSubmitted && showEditUser.user !== undefined && showEditUser.user !== null &&  showEditUser.user.groupName.trim().length <= 0 && <small className="p-error">{t('TXT_USERNAME')+t('MSG_MUST_BE_FILLED')}</small>}

                  <div className='h-1rem'/>

                  <FloatLabel className="mt-4">
                     <Dropdown 
                        panelClassName='border-round-xl border-noround-top shadow-3 p-2'
                        className="w-full border-round-xl text-base" 
                        id="plant"  
                        value={showEditUser.user !== undefined ? getSelectedPlantOption(showEditUser.user.plantName) : ''} 
                        onChange={(e) => onInputChange(e, 'plantName')} 
                        options={plantOptions} 
                        optionLabel="name" 
                        filter 
                        checkmark={true}  
                        highlightOnSelect={true} 
                     />
                     <label>{t('TXT_PLANT')}</label>
                  </FloatLabel>

                  <div className='h-2rem'/>

                  <div className="flex flex-row mb-2">
                     <Fieldset legend={t('TXT_SITE_ACCESS')} className='ml-4 w-4 border-round-xl text-sm custom-fieldset pt-0' style={{ height: '100px' }}>
                        <InputSwitch 
                           style={{marginTop: -5}}
                           checked={showEditUser.user !== undefined ? showEditUser.user.siteStatus: false} 
                           onChange={(e) => onInputChange(e, 'siteStatus')} 
                           className='ml-3'
                        />
                     </Fieldset>

                     <Fieldset legend={t('TXT_MOBILE_APP_ACCESS')} className='ml-8 w-4 border-round-xl text-sm custom-fieldset pt-0' style={{ height: '100px'}} >
                        <InputSwitch 
                           style={{marginTop: -5}}
                           checked={showEditUser.user !== undefined ? showEditUser.user.status: false} 
                           onChange={(e) => onInputChange(e, 'status')} 
                           className='ml-3'
                        />
                     </Fieldset>
                  </div>
         </Dialog>
      </div>
   );
}

export default ManageUsers;