
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './translations/en/translation.json';
import translationFR from './translations/fr/translation.json';
import translationPT from './translations/pt/translation.json';

const resources = {
    en: {
      translation: translationEN
    },
    pt: {
      translation: translationPT
    },
    fr: {
      translation: translationFR
  }
};

i18n.use(initReactI18next)
    .init({
      resources,
      lng: 'pt',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

export default i18n;