import axios from 'axios';
import Cookies from "js-cookie";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

const OrdersSearchs = (props) => {
    const { t } = useTranslation();
    const [plantOptions, setPlantOptions] = useState(null);

    const statusOptions =  [
        { name: t('TXT_APPROVED'), code: 'Approved' },
        { name: t('TXT_CANCELLED'), code: 'Cancelled' },
        { name: t('TXT_ORDER'), code: 'Order' },
        { name: t('TXT_OTHER'), code: 'Other' },
        { name: t('TXT_PRODUCTION'), code: 'Production' },
        { name: t('TXT_SENT'), code: 'Sent' },
        { name: t('TXT_STANDBY'), code: 'Standby' },
    ];

    const colorsOptions = [
        { name: t('TXT_COLOR_BLACK'), code: 'Black' },
        { name: t('TXT_COLOR_BLUE'), code: 'Blue' },
        { name: t('TXT_COLOR_BRICK'), code: 'Brick' },
        { name: t('TXT_COLOR_BROWN'), code: 'Brown' },
        { name: t('TXT_COLOR_CRISTAL'), code: 'Cristal' },
        { name: t('TXT_COLOR_GOLD'), code: 'Gold' },
        { name: t('TXT_COLOR_GREEN'), code: 'Green' },
        { name: t('TXT_COLOR_GREY'), code: 'Grey' },
        { name: t('TXT_COLOR_METAL'), code: 'Metal' },
        { name: t('TXT_COLOR_NATURAL'), code: 'Natural' },
        { name: t('TXT_COLOR_ORANGE'), code: 'Orange' },
        { name: t('TXT_COLOR_PINK'), code: 'Pink' },
        { name: t('TXT_COLOR_PURPLE'), code: 'Purple' },
        { name: t('TXT_COLOR_RED'), code: 'Red' },
        { name: t('TXT_COLOR_VIOLET'), code: 'Violet' },
        { name: t('TXT_COLOR_WHITE'), code: 'White' },
        { name: t('TXT_COLOR_YELLOW'), code: 'Yellow' },
    ];

    //EFFECT TO LOAD CLIENTS FROM API
    useEffect(() => {
        const controller = new AbortController();

        axios.get(
            `${process.env.REACT_APP_API_ADDRESS}/api/clients/findClients`,
            { 
                signal: controller.signal, 
                params: {
                    access: props.accessType, 
                    plant: props.plant,
                    group: props.group
                }, 
                headers: {
                    "x-auth-token":  JSON.stringify(Cookies.get("token"))
                }
            },
        ).then(result => {
            setPlantOptions(result.data);

            if (props.accessType.toLowerCase() === '2') {
                //setPlant({name: props.plant, code: props.plant});
            }
        }).catch(error => {
            if(axios.isCancel){
            } else {
                console.log(error)
            }
        })

        return () => {
            controller.abort()
        }
    }, [props]);

    return (
        <div style={{ width: '45rem', height:'35rem'}} className="mt-3 ml-2 mr-2">
            <h2 className='mb-6' style={{marginTop: -15}}><i className="pi pi-search text-lg mr-3" />{t('TXT_SEARCH_OPTIONS')}</h2>

            <div className="grid">
                <div className="col">
                    <div className="field">
                        <FloatLabel>
                            <Dropdown 
                                id="plant"
                                value={props.filters.plant} 
                                onChange={(e) => props.setFilters(e)} 
                                options={plantOptions|| []} 
                                optionLabel="name" 
                                placeholder={t('CAPTION_SELECT_OPTION')}
                                disabled={props.accessType.toLowerCase() === '2'}
                                showClear={props.accessType.toLowerCase() !== '2'}
                                filter 
                                panelClassName='border-round-xl border-noround-top shadow-3 p-2'
                                className="w-full border-round-xl" 
                            />
                            <label className="font-bold">{t('TXT_PLANT_GROUP')}</label>
                        </FloatLabel>
                    </div>

                    <div className="flex pt-3">
                        <div className="flex-grow-1 flex">
                            <FloatLabel>
                                <InputText 
                                    id="workNumber" 
                                    className="w-full border-round-xl" 
                                    value={props.filters.workNumber} 
                                    onChange={(e) => props.setFilters(e)} 
                                />
                                <label className="font-bold">{t('TXT_WORK_NUMBER')}</label>
                            </FloatLabel>
                        </div>
                        <div className="flex-shrink-0 flex">
                            <FloatLabel>
                                <InputText 
                                    id="workPosition" 
                                    className="w-6rem border-round-xl" 
                                    value={props.filters.workPosition} 
                                    onChange={(e) => props.setFilters(e)} 
                                />
                                <label className="font-bold">{t('TXT_POSITION')}</label>
                            </FloatLabel>
                        </div>
                    </div>

                    <div className="field mt-5">
                        <FloatLabel>
                            <InputText 
                                id="project" 
                                className="w-full border-round-xl" 
                                value={props.filters.project} 
                                onChange={(e) => props.setFilters(e)} 
                            />  
                            <label className="font-bold">{t('TXT_PROJECT')}</label>
                        </FloatLabel>
                    </div>

                    <div className="field mt-5">
                        <FloatLabel>
                            <InputText 
                                id="po" 
                                className="w-full border-round-xl" 
                                value={props.filters.po} 
                                onChange={(e) => props.setFilters(e)} 
                            />  
                            <label className="font-bold">{t('TXT_PO')}</label>
                        </FloatLabel>
                    </div>

                    <div className="field mt-5">
                        <FloatLabel>
                            <InputText 
                                id="clientID" 
                                className="w-full border-round-xl" 
                                value={props.filters.clientID} 
                                onChange={(e) => props.setFilters(e)} 
                            />  
                            <label className="font-bold">{t('TXT_CLIENT_ID')}</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className="col-1"></div>
                <div className="col">
                    <div className="field">
                        <FloatLabel>
                            <InputText 
                                id="invoice" 
                                className="w-full border-round-xl" 
                                value={props.filters.invoice} 
                                onChange={(e) => props.setFilters(e)} 
                            />  
                            <label className="font-bold">{t('TXT_INVOICE')}</label>
                        </FloatLabel>
                    </div>

                    <div className="field mt-5">
                        <FloatLabel>
                            <InputText 
                                id="nrWays" 
                                className="w-full border-round-xl" 
                                value={props.filters.nrWays} 
                                onChange={(e) => props.setFilters(e)} 
                            />  
                            <label className="font-bold">{t('TXT_NR_WAYS')}</label>
                        </FloatLabel>
                    </div>
                    

                    <div className="field mt-5">
                        <FloatLabel>
                            <Dropdown 
                                id="status"
                                value={props.filters.status} 
                                onChange={(e) => props.setFilters(e)} 
                                options={statusOptions} 
                                optionLabel="name" 
                                placeholder={t('CAPTION_SELECT_OPTION')}
                                showClear={true}
                                filter 
                                panelClassName='border-round-xl border-noround-top shadow-3 p-2'
                                className="w-full border-round-xl" 
                            />
                            <label className="font-bold">{t('TXT_STATUS')}</label>
                        </FloatLabel>
                    </div>
                    
                    <div className="field mt-5">
                        <FloatLabel>
                            <Dropdown 
                                id="colors"
                                value={props.filters.colors} 
                                onChange={(e) => props.setFilters(e)} 
                                options={colorsOptions} 
                                optionLabel="name" 
                                placeholder={t('CAPTION_SELECT_OPTION')}
                                showClear={true}
                                filter 
                                panelClassName='border-round-xl border-noround-top shadow-3 p-2'
                                className="w-full border-round-xl" />
            
                        <label className="font-bold">{t('TXT_COLOR')}</label>
                        </FloatLabel>
                    </div>
                </div>
            </div>


            <div className="field mt-5" style={{ textAlign: 'right' }}>
                <Button 
                    label={t('BTN_CLEAR')}  
                    icon="pi pi-delete-left" 
                    raised 
                    className='border-round-2xl mr-4'
                    onClick={() => {
                        props.clearSearch();
                    }}
                />

                <Button 
                    label={t('BTN_SEARCH')}  
                    icon="pi pi-search" 
                    raised 
                    className='border-round-2xl'
                    onClick={() => {
                        props.startSearch();
                    }}
                />  
            </div>
        </div>
    )
}

export default OrdersSearchs