import { Card } from 'primereact/card';
import React from 'react';
import logoImageDark from '../images/logos/logo_dark.svg';
import logoImage from '../images/logos/logo_ligth.svg';

function RemoveAccountPage() {
  
  return (
    <div className="card flex justify-content-center align-content-center mt-8 mb-5">
      <Card
        header={
            <div>
              <img className='flex justify-content-center align-content-center p-4' alt="Card" src={window.matchMedia('(prefers-color-scheme: dark)').matches ? logoImageDark: logoImage} height={160}/>
              <h1 className="flex justify-content-center align-content-center">How to Remove Your Account</h1>
            </div>
        }
        className="md:w-45rem shadow-8 border-round-3xl"
        style={{ width: "700px", padding: "2em" }}
        >
            <h5 style={{ fontSize: '18px', lineHeight: '1.6'}}>
              If you wish to remove your account, please follow the steps below
            </h5>
            <p style={{ fontSize: '18px', lineHeight: '1.6'}}>
              Send and email to <b>comercial@dinefer.com</b> with the following information:
            </p>
            <ol style={{ fontSize: '16px', lineHeight: '1.8'}}>
              <li><b>Subject:</b> Remove Clients Page/App Account </li>
              <li><b>Email Content:</b>
                <p>I would like to required the complete removal of my account.</p>
                <p>My Account Information:</p>
                <p>{`  - <EMAIL USED TO CREATE YOUR ACCOUNT>`}</p>
                <p>{`  - <LOGIN ASSOCIATED TO YOUR ACCOUNT>`}</p>
              </li>
            </ol>
            <p style={{ fontSize: '18px', lineHeight: '1.6'}}>
              Please note that once your account is deleted, it cannot be recovered.
            </p> 
      </Card>
    </div> 
  );
}

export default RemoveAccountPage;
